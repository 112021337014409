import { useState } from "react";
import { SubTaskRole } from "../../interfaces/Task";
import { TaskGenColorPicker } from "../../../utils/utils";

export interface SubTaskRoleItemProps {
    role: SubTaskRole;
    editCallback: CallableFunction;
    saveCallback: CallableFunction;
    deleteCallback: CallableFunction;
    assignmentsCallback: CallableFunction;
    position: number;
}

export default function SubTaskRoleItem(props: SubTaskRoleItemProps) {
    const [name, setName] = useState(props.role.name);
    const [color, setColor] = useState(props.role.color);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const handleShowEdit = () => {
        setShowEdit(true);
    }

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete the role? This action cannot be undone.')) {
            props.deleteCallback(props.role);
        }
    }

    const handleNameChange = (e: any) => {
        setName(e.target.value);
    }

    const saveGroup = (e: any) => {
        props.saveCallback(props.role, name, color, props.position);
        setShowColorPicker(false);
        setShowEdit(false);
    }

    const cancelNameChange = (e: any) => {
        setShowEdit(false);
    }

    const handleColorChange = (color: any) => {
        setShowColorPicker(false);
        setColor(color);
        props.saveCallback(props.role, name, color, props.position);
    }

    const handleShowColorPicker = () => {
        setShowColorPicker(!showColorPicker);
    }

    const itemStyle = {
        backgroundColor: color
    }

    return(
        <div className="sub-task-role-item-container" key={ 'sub-task-role-' + props.role._id }>
            <a className="toggle-color-picker" style={ itemStyle } onClick={ handleShowColorPicker }></a>
            { !showEdit && <div className="name">{ props.role.name }</div>}
            { showEdit &&
                <div className="edit-controls">
                    <input type="text" defaultValue={ props.role.name } onChange={ handleNameChange } />
                    <div className="actions">
                        <a className="ok-link" title={ 'Save' } onClick={ saveGroup }>{ 'Save' }</a>
                        <a className="ko-link" title={ 'Cancel' } onClick={ cancelNameChange }>{ 'Cancel' }</a>
                    </div>
                </div>
            }
            <div className="actions">
                { <a className="edit-link" title={ 'Edit name' } onClick={ handleShowEdit }></a> }
                { <a className="delete-link" title={ 'Delete group' } onClick={ handleDelete }></a> }
            </div>
            { showColorPicker && 
                <TaskGenColorPicker colorChangedCallback={ handleColorChange } />
            }
        </div>
    );
}