import { SubTaskGroup } from "../../interfaces/Task";

export interface SiteSubtaskGroupProps {
    group: SubTaskGroup;
}

export default function SiteSubtaskGroup(props: SiteSubtaskGroupProps) {
    const itemStyle = {
        backgroundColor: props.group.color
    }

    return(
        <div className="site-sub-task-group-container" key={ 'sub-task-group-' + props.group._id }>
            <div className="color" style={ itemStyle }></div>
            <div className="title">{ props.group.name }</div>
        </div>
    );
}