import { useState, useEffect } from "react";
import PrimaryHeader from "../header/PrimaryHeader";
import SecondaryHeader from "../header/SecondaryHeader";
import FeedbackBlock from "../blocks/FeedbackBlock";
import { Help, HelpItem } from "../interfaces/Help";
import parse from 'html-react-parser';
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

export default function HelpIndex() {
    const navigate = useNavigate();
    const [helps, setHelps] = useState<Help[]>();

    useEffect(() => {
        const requestOptionsGet = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        
        fetch('https://srv.taskgen.eu/v1/help', requestOptionsGet)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setHelps(data);
                
            });
        
    }, []);

    return (
        <div className="page">       
            <SecondaryHeader />
            <div className="page-content">
                <PrimaryHeader />
                <div className="page-content-inner">
                    <div className="row">
                        <h2>Help index</h2>
                    </div>
                    <div className="help-page">
                        <div className="help-index">
                            <ol className="help-list-index">
                                { helps && 
                                helps.map((i: Help, index) => {
                                    return(
                                        <li className="help" 
                                            key={ 'help-' + i._id + '-' + index}>
                                            <Link to={"/help#"+i._id}>{ i.name && <p>{ i.name }</p>}</Link>
                                            <ol>
                                                {i.items.map((item: HelpItem, index) => {
                                                    if(item.title != "" && item.title != i.name){
                                                        return (
                                                            <li className="help-item"
                                                            key={ 'help-item-' + i._id + '-' + index}>
                                                            <Link to={"/help#"+i._id}>{ item.title && <p>{ item.title }</p>}</Link>
                                                            </li>
                                                            
                                                        )
                                                    }
                                                })}
                                            </ol>
                                        </li>
                                    )
                                })}
                            </ol>
                        </div>
                        <div className="help-text">
                            <ol className="help-text-index">
                            { helps && 
                                helps.map((i: Help, index) => {
                                    return(
                                        <li className="help" 
                                            key={ 'help-' + i._id + '-' + index}>
                                            { i.name && <h4>{ i.name }</h4>}
                                    
                                            <ol>
                                                {i.items.map((item: HelpItem, index) => {
                                                
                                                    return (
                                                        <li className="help-item" id={''+i._id}
                                                        key={ 'help-item-' + i._id + '-' + index}>
                                                    
                                                        {item.title && <h5 className="help-title">{ item.title }</h5>}
                                                        {item.teaser && <p className="help-teaser">{parse(item.teaser)}</p>}
                                                        {item.body && <p className="help-body">{parse(item.body)}</p>}
                                                        {item.examples && <p className="help-examples">{parse(item.examples)}</p>}
                                
                                                        </li>
                                                        
                                                    )
                                                
                                                })}
                                            </ol>
                                        </li>
                                    )
                                })}
        
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <FeedbackBlock/>
        </div>);
}
