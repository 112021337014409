import Moment from 'moment';
import { Task } from "../interfaces/Task";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

interface TaskItemProps {
    task: Task;
    duplicateCallback: CallableFunction;
}

export default function TaskItemShared(props: TaskItemProps) {
    const navigate = useNavigate();
    const [owner, setOwner] = useState('Unknown name');

    const handleDuplicateTask = () => {
        fetch('https://srv.taskgen.eu/v1/task/' + props.task._id,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                const newTask: Task = data;
                newTask._id = '';
                newTask.name = props.task.name + ' (copy)';
                newTask.userId = localStorage.getItem('userId') ?? '';
                newTask.clonned = true;
                newTask.original_source = 'shared';
                newTask.original_task = props.task._id;

                const requestOptionsPost = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(newTask)
                };
                fetch('https://srv.taskgen.eu/v1/task', requestOptionsPost)
                    .then(response => {
                        if (response.status !== 200) {
                            navigate('/');
                        }
                        return response.json();
                    })
                    .then(data => {
                        props.duplicateCallback(data);
                    });
            });
    }

    useEffect(() => {
        const requestOptionsGet = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/user/' + props.task.userId, requestOptionsGet)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                if (data.profile !== undefined && (data.profile.firstname != '' || data.profile.lastname != '')) {
                    const nameArray: string[] = [];
                    if (data.profile.firstname != '') {
                        nameArray.push(data.profile.firstname);
                    }
                    if (data.profile.lastname != '') {
                        nameArray.push(data.profile.lastname);
                    }
                    setOwner(nameArray.join(' '));
                }
            }); 
    }, []);

    return(
        <div className="task-item teaser" key={ 'task-' + props.task._id }>
            <div className="title">
                { props.task.name }
            </div>
            <div className="meta">
                <div className="changed">
                    <div className="updated">
                        { 'Last updated' }: <span>{ Moment(props.task.updated).format('DD/MM/YYYY') }</span>
                    </div>
                    <div className="owner">                
                        { 'Owner'}: <span>{ owner }</span>
                    </div>
                </div>
                <div className="actions">
                    <button className="no-decoration duplicate" title="Duplicate" onClick={ handleDuplicateTask }></button>
                </div>
            </div>
        </div>
    );
}