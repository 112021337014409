import { Button, Form, FormGroup } from "react-bootstrap";
import { Org } from "../../interfaces/Org";
import { useNavigate } from "react-router-dom";

export interface OrgEditProps {
    org: Org;
    submitCallback: CallableFunction;
    closeCallback: CallableFunction;
}

export default function OrgEdit(props: OrgEditProps) {
    const navigate = useNavigate();
    const handleClose = () => {
        props.closeCallback();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let error = false;
        let error_messages: string[] = [];
        if (error) {
            alert(error_messages.join('\n'));
        } else {
            const newOrg = Object.assign({}, props.org);
            newOrg.name = event.target.name.value;
            const requestOptionsPost = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newOrg)
            };
            fetch('https://srv.taskgen.eu/v1/org/' + props.org._id, requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    props.submitCallback(newOrg);                    
                });
        }
    }

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ handleClose }></a>
                    <div className="admin-edit-org-container">
                    <h4>{ 'Edit Organization' }</h4>
                        <div className="org-form-container">
                            <Form onSubmit={ handleSubmit }>
                                <FormGroup className="name" controlId="name">
                                    <Form.Label aria-required={ true }>{ 'Name' }</Form.Label>
                                    <Form.Control type="text" required={ true } defaultValue={ props.org.name }
                                        placeholder={ 'Enter a name' } />
                                </FormGroup>
                                <div className="actions">
                                    <Button type="submit" variant="primary">{ 'Save' }</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    );
}