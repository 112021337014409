import { useState } from "react";
import ConfirmationDialog from "../../elements/ConfirmationDialog";
import { SubTask } from "../../interfaces/Task";
import EditSubTask from "./EditSubTask";

export interface SubTaskTeaserProps {
    taskId: string;
    subtask: SubTask;
    position: number;
    updateCallback: CallableFunction;
    deleteCallback: CallableFunction;
    cloneCallback: CallableFunction;
    sortUpCallback: CallableFunction;
    sortDownCallback: CallableFunction;
}

export default function SubTaskTeaser(props: SubTaskTeaserProps) {
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [showEditTask, setShowEditTask] = useState(false);
    
    const updated = new Date(props.subtask.updated);

    const handleShowDeleteConfirmDialog = () => {
        setShowDeleteConfirmDialog(true);
    }

    const handleDelete = () => {
        props.deleteCallback(props.subtask._id)
    }

    const handleCancel = () => {
        setShowDeleteConfirmDialog(false);
    }

    const handleEditSubTaskShow = () => {
        setShowEditTask(true);
    }

    const handleEditSubTaskHide = () => {
        setShowEditTask(false);
    }

    const handleEditSubTaskSave = (subtask: SubTask) => {
        props.updateCallback(subtask);
    }

    const handleSortUp = () => {
        props.sortUpCallback(props.position);
    }

    const handleSortDown = () => {
        props.sortDownCallback(props.position);
    }

    const handleCloneSubTaskShow = () => {
        props.cloneCallback(props.subtask);
    }

    return(
        <div className="sub-task-teaser-container" key={ 'sub-task-' + props.subtask._id }>
            <h4>{ props.subtask.name }</h4>
            <div className="updated">{ updated.toDateString() }</div>
            <div className="groups"><span className="label">{'Groups'}:</span> { props.subtask.groups.length }</div>
            <div className="roles"><span className="label">{'Roles'}:</span> { props.subtask.roles.length }</div>
            <div className="prompts"><span className="label">{'Prompts'}:</span> { props.subtask.prompts.length }</div>
            <div className="actions">
                <a className="sort-up" onClick={ handleSortUp } title={ 'Move Up' }></a>
                <a className="sort-down" onClick={ handleSortDown } title={ 'Move Up' }></a>
                <a className="clone-link" title={ 'Clone task' } onClick={ handleCloneSubTaskShow }></a>
                <a className="edit-link" title={ 'Edit task' } onClick={ handleEditSubTaskShow }></a>
                <a className="delete-link" title={ 'Delete task' } onClick={ handleShowDeleteConfirmDialog }></a>
            </div>
            { showDeleteConfirmDialog &&
                <ConfirmationDialog
                    message={ 'Are you sure you want to delete <strong>' + props.subtask.name + '</strong>? This action cannot be undone.' }
                    confirmLabel={ 'Delete' } confirmCallback={ handleDelete }
                    cancelLabel={ 'Cancel' } cancelCallback={ handleCancel } />
            }
            { showEditTask &&
                <EditSubTask taskId={ props.taskId } subTask={ props.subtask } saveCallback={ handleEditSubTaskSave } closeCallback={ handleEditSubTaskHide } />
            }
        </div>
    );
}