import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import './App.scss';
import Dashboard from './components/dashboard/Dashboard';
import HelpIndex from './components/help/HelpIndex';
import Tasks from './components/Tasks';
import Login from './components/user/Login';
import FirstLogin from './components/user/FirstLogin';
import EditTask from './components/tasks/EditTask';
import PreviewTask from './components/tasks/PreviewTask';
import TaskSite from './components/tasks/TaskSite';
import Users from './components/admin/users/Users';
import Helps from './components/admin/help/Helps';
import Faqs from './components/admin/faq/Faqs';
import Feedbacks from './components/admin/feedback/Feedbacks';
import Orgs from './components/admin/org/Orgs';
import ExportUsers from './components/admin/export/ExportUsers';
import { registerEventEnd, registerEventStart } from './utils/utils';
import ExportUserEvents from './components/admin/export/ExportUserEvents';
import ExportTasks from './components/admin/export/ExportTasks';
import ExportNlpEvents from './components/admin/export/ExportNlpEvents';

function TaskGenRoutes() {
  const location = useLocation();

  const [curRoute, setCurRoute] = useState('/');
  const [eventId, setEventId] = useState('');

  useEffect(() => {
    // Track path changes
    if (location.pathname.includes('/edit-task/')) {
      registerEventStart('edit task', '').then(data => {
        setEventId(data);
      });
    }
    if (curRoute.includes('/edit-task/') && !location.pathname.includes('/edit-task/') && eventId.length > 0) {
      registerEventEnd(eventId);
    }
    
    if (location.pathname.includes('/help')) {
      registerEventStart('help', '').then(data => {
        setEventId(data);
      });
    }
    if (curRoute.includes('/help') && !location.pathname.includes('/help') && eventId.length > 0) {
      registerEventEnd(eventId);
    }
    
    setCurRoute(location.pathname);
  
  }, [location]);

  return(
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/dashboard" element={<Dashboard name="John Doe"/>}></Route>
      <Route path="/help" element={<HelpIndex />}></Route>
      <Route path='/first-login' element={<FirstLogin />}></Route>
      <Route path="/tasks" element={< Tasks />}></Route>
      <Route path="/edit-task/:id" element={<EditTask />}></Route>
      <Route path="/preview-task/:id" element={<PreviewTask />}></Route>
      <Route path="/task-site/:id/:mode" element={<TaskSite />}></Route>
      <Route path="/admin/users" element={<Users />}></Route>
      <Route path="/admin/help" element={<Helps />}></Route>
      <Route path="/admin/faqs" element={<Faqs />}></Route>
      <Route path="/admin/feedbacks" element={<Feedbacks />}></Route>
      <Route path="/admin/orgs" element={<Orgs />}></Route>
      <Route path="/admin/export/users" element={<ExportUsers />}></Route>
      <Route path="/admin/export/user-events" element={<ExportUserEvents />}></Route>
      <Route path="/admin/export/tasks" element={<ExportTasks />}></Route>
      <Route path="/admin/export/nlp-events" element={<ExportNlpEvents />}></Route>
    </Routes>
  )
}

function App() {
  return (
    <Router>
      <div className="app">
        <TaskGenRoutes />
      </div>
    </Router>);
}

export default App;
