export const emptyFeedback = {
    _id: '',
    issue: '',
    body: '',
    section: '',
    date: new Date(),
    state: 0,
    user: {
        _id: '',
        email: ''
    }
}

export interface Feedback {
    _id: string,
    issue: string,
    body: string,
    section: string,
    date: Date,
    state: number, //new = 0, read = 1
    user: {
        _id: string,
        email: string
    }
}
