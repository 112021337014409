import { Media } from "./Media";

export interface Task {
    _id: string;
    name: string;
    pretasks: any[];
    tasks: SubTask[];
    postasks: PosTask[];
    updated: Date;
    userId: string;
    active: boolean;
    authorship: string;
    aims: number[];
    aim_other: string;
    specific_aim: string;
    proficiency_level: number[];
    proficiency_level_comments: string;
    sessions: TaskSession[];
    clonned: boolean;
    original_source: string;
    original_task: string;
    weight: number;
}

export interface PreTask {
    _id: string;
    name: string;
    updated: Date;
    type: string;
    instructions: string;
    text: string;
    media: Media[];
    session: string;
    students: boolean;
    teachers: boolean;
    teacher_notes: string;
}

export interface PreTaskText {
    id: number;
    name: string;
    introduction: string;
    text: string;
    updated: Date;
}

export interface PreTaskAudio {
    id: number;
    name: string;
    introduction: string;
    text: string;
    media: Media[];
    updated: Date;
}

export interface SubTask {
    _id: string;
    name: string;
    updated: Date;
    groups: SubTaskGroup[];
    roles: SubTaskRole[];
    prompts: SubTaskPrompt[];
    goal: string;
    students: number;
    grouping: string;
    studentsPerGroup: number;
    session: string;
    students_session: boolean;
    teachers_session: boolean;
    teacher_notes: string;
}

export interface PosTask {
    _id: string;
    name: string;
    updated: Date;
    type: String;
    instructions: string;
    text: string;
    media: Media[];
    session: string;
    students: boolean;
    teachers: boolean;
    teacher_notes: string;
}

export interface SubTaskStudent {
    _id: string,
    name: string,
    role: number,
    prompt: number
}
export interface SubTaskGroup {
    _id: string;
    name: string;
    color: string;
    students: SubTaskStudent[];
}

export interface SubTaskRole {
    _id: string;
    name: string;
    color: string;
    groups: number[];
}

export interface SubTaskPrompt {
    _id: string;
    name: string;
    instructions: string;
    media: Media[];
    text: string;
    roles: number[];
    teacher_notes: string;
}

export interface TaskSession {
    _id: string,
    name: string,
    datetime: Date
}

export const aimsOfTask = [
    {
        value: 1,
        description: 'To describe an object, person or place'
    },
    {
        value: 2,
        description: 'To solve a communication problem or gap'
    },
    {
        value: 3,
        description: 'To create a design or plan'
    },
    {
        value: 4,
        description: 'To to make a decision or choice'
    },
    {
        value: 5,
        description: 'To explain a process'
    },
    {
        value: 6,
        description: 'To tell a story'
    },
    {
        value: 7,
        description: 'To reach an agreement'
    },
    {
        value: 8,
        description: 'To learn new content (focus on meaning)'
    },
    {
        value: 9,
        description: 'To fill out a form'
    },
    {
        value: 10,
        description: 'To make a complaint'
    },
    {
        value: 11,
        description: 'To request information'
    },
    {
        value: 12,
        description: 'To formulate an argument'
    },
    {
        value: 13,
        description: 'To express an opinion'
    },
    {
        value: 14,
        description: 'To foster critical language awareness'
    }
];

export const proficiencyLevelsOfTask = [
    {
        value: 1,
        description: 'A1 Movers'
    },
    {
        value: 2,
        description: 'A2 Key'
    },
    {
        value: 3,
        description: 'B1 Preliminary'
    },
    {
        value: 4,
        description: 'B2 First'
    },
    {
        value: 5,
        description: 'C1 Advanced'
    },
    {
        value: 6,
        description: 'C2 Proficiency'
    }
];