import { Media } from "../interfaces/Media";

export interface MediaPreviewProps {
    media: Media;
}

export default function MediaPreview(props: MediaPreviewProps) {
    let mediaClass = '';
    switch (props.media.mimetype) {
        case 'image/jpeg':
        case 'image/png':
            mediaClass = 'image';
            break;
        case 'video/mp4':
            mediaClass = 'video';
            break;
        case 'audio/mpeg':
            mediaClass = 'audio';
            break;
        case 'application/pdf':
            mediaClass = 'pdf';
            break;
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            mediaClass = 'pdf';
            break;
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            mediaClass = 'pdf';
            break;
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            mediaClass = 'pdf';
            break;
        case 'application/zip':
        case 'application/x-rar':
            mediaClass = 'pdf';
            break;
        case 'text/plain':
            mediaClass = 'pdf';
            break;
    }
    return (
        <div className={ 'media-item-preview ' + mediaClass}>
            { (props.media.mimetype == 'image/jpeg' || props.media.mimetype == 'image/png') &&
                <div className="image media-item">
                    <div className="file">
                        <img src={ 'https://srv.taskgen.eu/' + props.media.path } />
                    </div>
                </div>
            }
            { (props.media.mimetype == 'video/mp4') &&
                <div className="video media-item">
                    <video controls>
                        <source src={ 'https://srv.taskgen.eu/' + props.media.path } />
                    </video>
                </div>
            } 
            { (props.media.mimetype == 'audio/mpeg') &&
                <div className="audio media-item">
                    <audio controls>
                        <source src={ 'https://srv.taskgen.eu/' + props.media.path } type="audio/mpeg" />
                    </audio>
                </div>
            }
            { (props.media.mimetype == 'application/pdf') &&
                <div className="pdf media-item">
                    <a href={ 'https://srv.taskgen.eu/' + props.media.path } target="_blank" title={ props.media.name }></a>
                </div>
            }
            { (props.media.mimetype == 'application/vnd.ms-powerpoint' || props.media.mimetype == 'application/vnd.openxmlformats-officedocument.presentationml.presentation') &&
                <div className="presentation media-item">
                    <a href={ 'https://srv.taskgen.eu/' + props.media.path } target="_blank" title={ props.media.name }></a>
                </div>
            }
            { (props.media.mimetype == 'application/vnd.ms-excel' || props.media.mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') &&
                <div className="spreadsheet media-item">
                    <a href={ 'https://srv.taskgen.eu/' + props.media.path } target="_blank" title={ props.media.name }></a>
                </div>
            }
            { (props.media.mimetype == 'application/msword' || props.media.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                <div className="document media-item">
                    <a href={ 'https://srv.taskgen.eu/' + props.media.path } target="_blank" title={ props.media.name }></a>
                </div>
            }
            { (props.media.mimetype == 'application/zip' || props.media.mimetype == 'application/x-rar') &&
                <div className="compress media-item">
                    <a href={ 'https://srv.taskgen.eu/' + props.media.path } target="_blank" title={ props.media.name }></a>
                </div>
            }
            { (props.media.mimetype == 'text/plain') &&
                <div className="text media-item">
                    <a href={ 'https://srv.taskgen.eu/' + props.media.path } target="_blank" title={ props.media.name }></a>
                </div>
            }
            <a href={ 'https://srv.taskgen.eu/' + props.media.path } target="_blank" title={ props.media.name }>
                { props.media.name }
            </a>
        </div>
    );
}