
import parse from "html-react-parser";
import { PreTask } from "../../interfaces/Task";
import { Media } from "../../interfaces/Media";
import MediaPreview from "../../elements/MediaPreview";
import { Table } from "react-bootstrap";
import { replaceOembed } from "../../../utils/utils";

export interface SitePreTaskProps {
    pretask: PreTask;
    mode: string;
}

export default function SitePreTask(props: SitePreTaskProps) {
    return(
        <div className="site-pretask">
            <div className="inner">
                <div className="pretask-name">
                    <strong>{ props.pretask.name }</strong>
                </div>
                <div className="pretask-content">
                    { props.pretask.instructions &&
                        <div className={ 'instructions' + (props.pretask.media.filter((m: Media) => m.share == true).length == 0 ? ' no-medias' : '') }>
                            <label><strong>{ 'Aim and instructions' }</strong></label>
                            <div className="value">{ parse(replaceOembed(props.pretask.instructions)) }</div>
                        </div>
                    }
                    { props.pretask.teacher_notes && props.mode == 't' &&
                        <div className={ 'teacher-notes' }>
                            <label><strong>{ 'Teacher notes' }</strong></label>
                            <div className="value">{ parse(replaceOembed(props.pretask.teacher_notes)) }</div>
                        </div>
                    }
                    { props.pretask.media.length > 0 &&
                        <div className="medias-list">                                        
                            <Table responsive={ true }>
                                <thead>
                                    <tr>
                                        <th>{ 'Resource' }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { props.pretask.media.map((m: Media) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <MediaPreview media={ m } />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    }
                    { props.pretask.text &&
                        <div className="text">
                            <label><strong>{ 'Text' }</strong></label>
                            <div className="value">{ parse(replaceOembed(props.pretask.text)) }</div>
                        </div>
                    }
                    { !props.pretask.text &&
                        <div className="text">
                            { 'No text specified for this pre-task' }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}