import { SubTask, SubTaskGroup, SubTaskPrompt, SubTaskRole } from "../../interfaces/Task";
import SiteSubTaskPrompt from "./SiteSubTaskPrompt";
import SiteSubTaskRole from "./SiteSubTaskRole";
import SiteSubtaskGroup from "./SiteSubtaskGroup";
import parse from 'html-react-parser';

export interface SiteSubTaskProps {
    subtask: SubTask;
    mode: string;
}

export default function SiteSubtask(props: SiteSubTaskProps) {
    return(
        <div className="site-subtask">
            <div className="inner">
                <h4>{ props.subtask.name }</h4>
                { props.subtask.goal &&
                    <div className="goal">
                        { props.subtask.goal }
                    </div>
                }
                { props.subtask.teacher_notes &&
                    <div className="teacher-notes">
                        <label><strong>{ 'Teacher notes' }</strong></label>
                        <div className="value">{ parse(props.subtask.teacher_notes) }</div>
                    </div>
                }
                <div className="groups">
                    <h5>{ 'Groups' }</h5>
                    { props.subtask.groups.length == 0 &&
                        <div className="message error">
                            { 'There are no groups'}
                        </div>
                    }
                    { props.subtask.groups.length > 0 &&
                        <div className="groups-list">
                            <div className="inner">
                                { props.subtask.groups.map((g: SubTaskGroup) => {
                                    return <SiteSubtaskGroup group={ g } />
                                }) }
                            </div>
                        </div>
                    }
                </div>
                <div className="roles">
                    <h5>{ 'Roles' }</h5>
                    { props.subtask.roles.length == 0 &&
                        <div className="message error">
                            { 'There are no roles'}
                        </div>
                    }
                    { props.subtask.roles.length > 0 &&
                        <div className="roles-list">
                            <div className="inner">
                                { props.subtask.roles.map((r: SubTaskRole) => {
                                    return <SiteSubTaskRole role={ r } groups={ props.subtask.groups } />
                                }) }
                            </div>
                        </div>
                    }
                </div>
                <div className="prompts">
                    <h5>{ 'Prompts' }</h5>
                    { props.subtask.prompts.length == 0 &&
                        <div className="message error">
                            { 'There are no prompts'}
                        </div>
                    }
                    { props.subtask.prompts.length > 0 &&
                        props.subtask.prompts.map((p: SubTaskPrompt) => {
                            return <SiteSubTaskPrompt prompt={ p } mode={ props.mode } />
                        })
                    }
                </div>
            </div>
        </div>
    );
}