import { useState } from "react";

const aiTools = [
    { 
        category: 'Text',
        tools: [
            {
                name: 'Cathoven',
                url: 'https://nexthub.cathoven.com/cefr',
                description: <p>Cathoven will automatically <strong>detect the CEFR level</strong> of the text you insert in their text box (up to
                    250 words if you want to do it for free). You can also <strong>generate exercises</strong> automatically. You
                    <strong>generate new texts</strong> if you insert a topic.<br />
                    You can copy and paste your classified texts into the taskGen textbox and use the button
                    “Analyze” to get a complete linguistic analysis of your text. Then you can select some linguistic
                    feature that are relevant to your task</p>
            },
            {
                name: 'International Task Bank',
                url: 'https://tblt.indiana.edu/index.html',
                description: <p>You can get some inspiration about possible pre-tasks in the International Task Bank where you can obtain and download full task designs.</p>
            },
            {
                name: 'Quizlet',
                url: 'https://theresanaiforthat.com/s/language+games/',
                description: <p>Quizlet is a tool that allows students to learn vocabulary by means of customised flashcards</p>
            },
            {
                name: 'Magicschool.ai',
                url: 'https://www.magicschool.ai/',
                description: <p>If you are in primary or secondary, here's a list of AI tools you can use to assist your teaching. Signing up and using the tools is free.<br />
                    You can bring the outcomes of those tools and cut and paste them into the taskGen textbox.</p>
            }
        ]
    },
    { 
        category: 'Video',
        tools: [
            {
                name: 'edpuzzle',
                url: 'https://edpuzzle.com/',
                description: <p>edpuzzle will let you <strong>create interactive videos</strong> where questions appear as they watch the video. You can include the link to the edpuzzle video in the taskGen text box.</p>
            },
            {
                name: 'veed.io',
                url: 'https://tblt.indiana.edu/index.html',
                description: <p>The program can <strong>generate videos</strong> on the basis of the topic you choose. You have limited access as a free user.<br />
                    You can also insert the link in taskGen or upload the audio files you create with these apps.</p>
            }
        ]
    },
    { 
        category: 'Audio',
        tools: [
            {
                name: 'ttsfree.com',
                url: 'https://ttsfree.com/',
                description: <p>You can insert a text and have the program <strong>read the text for you</strong>. You have limited access as a free user.</p>
            },
            {
                name: 'Quizbot',
                url: 'https://quizbot.ai/dashboard/user',
                description: <p>Quizbot will let you <strong>transform an audio file into text</strong> and then <strong>generate questions</strong>.<br />
                    You can copy and paste the question into the taskGen text box. You can also insert the link in taskGen or upload the audio files you create with these apps.</p>
            }
        ]
    },
    { 
        category: 'Written dialog',
        tools: [
            {
                name: 'IMSDB.com',
                url: 'https://imsdb.com/',
                description: <p></p>
            },
            {
                name: 'Drew\'s script-o-rama',
                url: 'https://www.script-o-rama.com/',
                description: <p>Two classic web sites where you can <strong>find movie scripts</strong> and scripts from <strong>TV series</strong>.</p>
            },
            {
                name: 'Toolsaday',
                url: 'https://toolsaday.com/writing/dialogue-generator',
                description: <p>Use this tool to <strong>generate a dialogue</strong>. Just describe your objective and a bit of a context, and it’ll give you a perfectly crafted dialogue for you.<br />
                    You can copy and paste the dialogues into the taskGen text box. Then you can use the function “Analyze” to get a complete analysis of the linguistic features of the text. You can automatically highlight and or change font size to bring attention to those features (input enhancement)</p>
            }
        ]
    },
    { 
        category: 'Photo',
        tools: [
            {
                name: 'Pixlr.com',
                url: 'https://pixlr.com/image-generator/',
                description: <p>You can <strong>generate new pictures</strong>.</p>
            },
            {
                name: 'Claude.ai',
                url: 'https://claude.ai/new',
                description: <p>You can upload a picture and the program will <strong>generate questions</strong> about the picture automatically.</p>
            }
        ]
    },
    { 
        category: 'Speech transcript',
        tools: [
            {
                name: 'Naturalreaders.com',
                url: 'https://www.naturalreaders.com/online/',
                description: <p>You can use this tool to <strong>have a text be read</strong> by a computerized voice.</p>
            },
            {
                name: 'ttsfree.com',
                url: 'https://ttsfree.com/',
                description: <p>You can insert a text and have the program <strong>read the text for you</strong>. You have limited access as a free user.
                You can copy the link in the taskGen text box or upload the audio file onto to your speech transcript pre-task.</p>
            }
        ]
    },
    { 
        category: 'Interaction activity',
        tools: [
            {
                name: 'International Task Bank',
                url: 'https://tblt.indiana.edu/index.html',
                description: <p>You can <strong>get ideas</strong> and example about interaction activities in the International Task Bank.</p>
            },
            {
                name: 'Toolsaday',
                url: 'https://toolsaday.com/writing/dialogue-generator',
                description: <p>Use this tool to <strong>generate a dialogue</strong>. Just describe your objective and a bit of a context, and it’ll give you a perfectly crafted dialogue for you.</p>
            },
        ]
    },
    { 
        category: 'Interaction activity',
        tools: [
            {
                name: 'Theresanaiforthat.com',
                url: 'https://quizlet.com/es',
                description: <p></p>
            }
        ]
    }
];

export default function AITools() {
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(!show);
    }

    return (
        <div className="aitools-container">
            <button className="toggle" onClick={ handleShow }>{ 'AI Tools and other resources' } { show ? ' (hide)' : '' }</button>
            <div className={ 'aitools-box' + (show ? ' show' : '') }>
                <div className="inner">
                    <div className="items">
                        { aiTools.map((cat: any) => {
                            return <div className="cateogry-item">
                                <h5>{ cat.category }</h5>
                                <div className="tools-list">
                                    { cat.tools.map((t: any) => {
                                        return <div className="tool-item">
                                            <div className="tool-title">
                                                <a href={ t.url } title={ t.name } target="_blank">{ t.name }</a>
                                            </div>
                                            <div className="tool-description">
                                                { t.description }
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}