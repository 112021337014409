import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { PreTask } from "../../interfaces/Task";
import { Media } from "../../interfaces/Media";
import Uploader from "../../elements/Uploader";
import CustomEditor from "../../elements/CustomEditor";
import AnalyzedText from "../../elements/AnalyzedText";
import Help from "../../elements/HelpContent";
import { registerEventEnd, registerEventStart } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import AITools from "../../elements/AITools";

const emptyPreTask: any = {
    _id: '',
    name: '',
    type: 'activity',
    instructions: '',
    media: [],
    text: '',
    updated: new Date()
}

export interface EditPreTaskActivityProps {
    task_id: string;
    id: string;
    position: number;
    saveForm: CallableFunction;
    closeForm: CallableFunction;
}

export default function EditPreTaskActivity(props: EditPreTaskActivityProps) {
    const navigate = useNavigate();
    const [eventId, setEventId] = useState('');
    const [preTask, setPreTask] = useState<PreTask>(emptyPreTask);
    const [instructions, setInstructions] = useState('');
    const [teacher_notes, setTeacherNotes] = useState('');
    const [text, setText] = useState('');
    const [media, setMedia] = useState<Media[]>([]);
    const [analyzedText, setAnalyzedText] = useState();
    const [showAnalyze, setShowAnalyze] = useState(false);

    const handleMediaUpload = (media: Media[]) => {
        const newMedia = media.map((m: Media) => {
            return m;
        });
        setMedia(newMedia);
    }
    
    const handleInstructionsChange = (data: string) => {
        setInstructions(data);
    }

    const handleTeacherNotesChange = (data: string) => {
        setTeacherNotes(data);
    }

    const handleTextChange = (data: string) => {
        setText(data);
    }

    const handleClose = () => {
        registerEventEnd(eventId);
        props.closeForm();
    }

    const handleSave = (e: any) => {
        e.preventDefault();
        registerEventEnd(eventId);
        preTask.name = e.target.name.value;
        preTask.instructions = instructions;
        preTask.media = media.map((m: Media) => { return m; });
        preTask.text = text;
        preTask.teacher_notes = teacher_notes;
        props.saveForm(preTask);
    }

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/task/' + props.task_id + '/pretask/' + props.id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                const newMedia: Media[] = data.media.map((m: Media) => { return m; });
                setMedia(newMedia);
                setPreTask(data);
            });

        registerEventStart('edit pretask activity', props.task_id).then(data => { 
            setEventId(data);
        });

        return () => {
            registerEventEnd(eventId);
        };
    }, []);

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ handleClose }></a>
                    <div className="new-task-container interaction-activity">
                        <div className="inner">
                            <h2>
                                { 'Edit Pre-Task: Interaction activity' }
                                <Help id={ '652fdebb1e5cf99991b5725f' } />
                            </h2>
                            <AITools />
                            <Form className="new-pre-task-form" onSubmit={ handleSave }>
                                <div className="fields-container">
                                    <Form.Group className="field field-title">
                                        <Form.Label>{ 'Title of the Pre-Task' }</Form.Label>
                                        <Form.Control type="text" name="name" required defaultValue={ preTask.name } />
                                    </Form.Group>
                                    <div className="field field-instructions">
                                        <CustomEditor
                                            change={ handleInstructionsChange }
                                            data={ preTask.instructions }
                                            id="instructions"
                                            label={ 'Aim and instructions'}
                                            npl={ false }
                                        />
                                    </div>
                                    <div className="field field-teachernotes">
                                        <CustomEditor
                                            change={ handleTeacherNotesChange }
                                            data={ preTask.teacher_notes }
                                            id="teachernotes"
                                            label={ 'Teacher notes '}
                                            npl={ false }
                                        />
                                    </div>
                                    <Uploader media={ media } save={ handleMediaUpload } />
                                    <div className="field field-text">
                                        <CustomEditor
                                            change={ handleTextChange }
                                            data={ preTask.text }
                                            id="text"
                                            label={ 'Text '}
                                            npl={ true }
                                        />
                                    </div>
                                </div>
                                { showAnalyze && <AnalyzedText analyzedText={ analyzedText } /> }
                                <div className="actions">
                                    <button className="btn btn-primary" type="submit">
                                        { 'Save' }
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}