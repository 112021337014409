import { Component } from "react";
import Navigation from "./Navigation";

export default class SecondaryHeader extends Component{
    render() {
        return(
            <aside className="secondary-header">
                <Navigation />
            </aside>
        )
    }
}