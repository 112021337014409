import { useState } from "react";
import { SubTaskGroup, SubTaskPrompt, SubTaskRole, SubTaskStudent } from "../../interfaces/Task";
import { TaskGenColorPicker } from "../../../utils/utils";
import SiteSubTaskStudent from "./SiteSubTaskStudent";
import SiteSubTaskStudentEdit from "./SiteSubTaskStudentEdit";

export interface SubTaskGroupItemProps {
    group: SubTaskGroup;
    saveCallback: CallableFunction;
    cloneCallback: CallableFunction;
    deleteCallback: CallableFunction;
    position: number;
    roles: SubTaskRole[];
    prompts: SubTaskPrompt[];   
}

export default function SubTaskGroupItem(props: SubTaskGroupItemProps) {
    const [name, setName] = useState(props.group.name);
    const [color, setColor] = useState(props.group.color);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [students, setStudents] = useState<SubTaskStudent[]>(props.group.students);
    const [curPage, setCurPage] = useState(0);
    const [showEditStudent, setShowEditStudent] = useState(false);
    const [curStudent, setCurStudent] = useState<any>(undefined);

    const handleShowEdit = () => {
        setShowEdit(true);
    }

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete the group? This action cannot be undone.')) {
            props.deleteCallback(props.group);
        }
    }

    const handleNameChange = (e: any) => {
        setName(e.target.value);
    }

    const saveGroup = (e: any) => {
        props.saveCallback(props.group, name, color, students, props.position);
        setShowColorPicker(false);
        setShowEdit(false);
    }

    const cancelNameChange = (e: any) => {
        setShowEdit(false);
    }

    const handleColorChange = (color: any) => {
        props.saveCallback(props.group, name, color, students, props.position);
        setShowColorPicker(false);
        setColor(color);
    }

    const handleShowColorPicker = () => {
        setShowColorPicker(!showColorPicker);
    }

    const handleAddStudent = () => {
        const newStudent: SubTaskStudent = {
            _id: '',
            name: 'Student ' + (students.length + 1),
            role: -1,
            prompt: -1
        }
        const newStudents = [
            ...students,
            newStudent
        ];
        setStudents(newStudents);  
        props.saveCallback(props.group, name, color, newStudents, props.position);
    }

    const handleSaveSubtaskStudent = (student: SubTaskStudent) => {
        const newStudents = students.map((s: SubTaskStudent) => {
            if (s._id == student._id) {
                return student;
            } else {
                return s;
            }
        });
        setStudents(newStudents);
        props.saveCallback(props.group, name, color, newStudents, props.position);
        setCurStudent(undefined);
        setShowEditStudent(false);
    }

    const handleDeleteSubtaskStudent = (student: SubTaskStudent) => {
        const newStudents = students.filter((s: SubTaskStudent) => s._id != student._id);
        setStudents(newStudents);  
        props.saveCallback(props.group, name, color, newStudents, props.position);
    }
 
    const itemStyle = {
        backgroundColor: color
    }

    const handlePrevPage = () => {
        let newCurPage = curPage - 1;
        if (newCurPage < 0) newCurPage = 0;
        setCurPage(newCurPage);
    }

    const handleNextPage = () => {
        let newCurPage = curPage + 1;
        if (newCurPage > Math.floor(students.length / 6)) newCurPage = Math.floor(students.length / 6);
        setCurPage(newCurPage);
    }

    const handleEditSubtaskStudent = (event, index: number) => {
        setCurStudent(students[index]);
        setShowEditStudent(true);
    }

    const handleCancelSubtaskStudentEdit = () => {
        setCurStudent(undefined);
        setShowEditStudent(false);
    }

    const handleClone = () => {
        const newGroup = Object.assign({}, props.group);
        newGroup.name = name + ' (copy)';
        newGroup.color = color;
        props.cloneCallback(newGroup);
    }

    return(
        <div className="sub-task-group-item-container" key={ 'sub-task-group-' + props.group._id } style={ itemStyle }>
            <div className="students-list">
                { students.length > 0 &&
                    <ul>
                        { students.map((s: SubTaskStudent, index) => {
                            const page = Math.floor(index / 6);
                            return <li className= { page == curPage ? 'visible' : '' } key={ 'student-' + props.group._id + '-' + index }>
                                <SiteSubTaskStudent student={ s } 
                                    saveCallback={ handleSaveSubtaskStudent }
                                    editCallback={ (event) => handleEditSubtaskStudent(event, index) }
                                    deleteCallback={ handleDeleteSubtaskStudent }
                                    roles={ props.roles } prompts={ props.prompts } />
                            </li>
                        })}
                    </ul>
                }
                { Math.floor(students.length / 6) > 0 &&
                    <div className={ 'students-pager' }>
                        { curPage > 0 &&
                            <div className="prev-black-link" onClick={ handlePrevPage }></div>
                        }
                        { curPage < Math.floor(students.length / 6) &&
                            <div className="next-black-link" onClick={ handleNextPage }></div>
                        }
                    </div>
                }
            </div>
            <div className="group-info">
                { !showEdit && <div className="name">{ name }</div>}
                { showEdit &&
                    <div className="edit-controls">
                        <input type="text" defaultValue={ name } onChange={ handleNameChange } />
                        <a className="ok-link" title={ 'Save' } onClick={ saveGroup }></a>
                        <a className="ko-link" title={ 'Cancel' } onClick={ cancelNameChange }></a>
                    </div>
                }
                <div className="actions">
                    <a className="toggle-color-picker" title={ 'Change color' } onClick={ handleShowColorPicker }></a>
                    <a className="add-student-link" title={ 'Add new student' } onClick={ handleAddStudent }></a>
                    <a className="edit-link" title={ 'Edit name' } onClick={ handleShowEdit }></a>
                    <a className="clone-link" title={ 'Clone' } onClick={ handleClone }></a>
                    <a className="delete-link" title={ 'Delete group' } onClick={ handleDelete }></a>
                </div>
                { showColorPicker && 
                    <TaskGenColorPicker colorChangedCallback={ handleColorChange } />
                }
            </div>
            { showEditStudent && curStudent != -1 && 
                <SiteSubTaskStudentEdit student={ curStudent } 
                    saveCallback={ handleSaveSubtaskStudent }
                    cancelCallback={ handleCancelSubtaskStudentEdit }
                    roles={ props.roles } prompts={ props.prompts } />
            }
        </div>
    );
}