import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Faq } from "../interfaces/Faq";
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";

export default function FaqsBlock() {
    const navigate = useNavigate();

    const [faqs, setFaqs] = useState<Faq[]>([]);

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/faq', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setFaqs(data);
            });
    }, []);
    return (
        <div className="faqs box">
            <h2>{ 'FAQs' }</h2>
            <div className="message margin-bottom-small">
                { 'Check out some of the questions teachers/designers have asked us so far. The answers will help you to get the most of the tool' }
            </div>
            <div className="faqs-list">
                <Accordion>
                    { faqs.map((f: Faq, index) => {
                        return <Accordion.Item key={ 'event-key-' + index } eventKey={ 'event-key-' + index }>
                            <Accordion.Header>{ f.title }</Accordion.Header>
                            <Accordion.Body>{ parse(f.body) }</Accordion.Body>
                        </Accordion.Item>
                    })}
                </Accordion>
            </div>
        </div>
    )
}
