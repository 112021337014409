import { SubTaskGroup, SubTaskRole } from "../../interfaces/Task";
import SiteSubtaskGroup from "./SiteSubtaskGroup";

export interface SiteSubTaskRoleProps {
    role: SubTaskRole;
    groups: SubTaskGroup[]
}

export default function SiteSubTaskRole(props: SiteSubTaskRoleProps) {
    const itemStyle = {
        backgroundColor: props.role.color
    }

    return(
        <div className="site-sub-task-role-container" key={ 'sub-task-role-' + props.role._id }>
            <div className="color" style={ itemStyle }></div>
            <div className="title">{ props.role.name }</div>
            { props.role.groups.length > 0 && 
                <div className="groups-belong">
                    <strong>{ 'Belongs to groups'}:</strong>
                    { props.groups.map((g: SubTaskGroup, index) => {
                        if (props.role.groups.indexOf(index) !== -1) {
                            const groupItemStyle = {
                                backgroundColor: g.color
                            }
                            return <span className="group-assigned">
                                    <span style={ groupItemStyle }></span> { g.name }
                                </span>;
                        }
                    })}
                </div>
            }
        </div>
    );
}