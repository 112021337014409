import { useState } from "react";
import { Form } from "react-bootstrap";
import { SubTask } from "../../interfaces/Task";
import CustomEditor from "../../elements/CustomEditor";

export interface SubTaskTeacherNotesEditProps {
    subTask: SubTask;
    save: CallableFunction;
}

export default function SubTaskTeacherNotesEdit(props: SubTaskTeacherNotesEditProps) {
    const [teacher_notes, setTeacherNotes] = useState(props.subTask.teacher_notes);
    
    const save = (event: any) => {
        event.preventDefault();
        props.save(teacher_notes);
    }

    const handleTeacherNotesChange = (data: string) => {
        setTeacherNotes(data);
    }

    const handleTextAnalyzed = (data: any) => {
        
    }

    return(
        <div className="edit-sub-task-prompt-container">
            <div className="inner">
                <h2>{ 'Edit Teacher notes' }</h2>
                <Form className="sub-task-edit-form" onSubmit={ save }>
                    <div className="field field-teachernotes">
                        <CustomEditor
                            change={ handleTeacherNotesChange }
                            data={ teacher_notes }
                            id="teachernotes"
                            label=''
                            npl={ false }
                        />
                    </div>
                    <div className="actions">
                        <button className="btn btn-primary" type="submit">
                            { 'Save' }
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    );
}