import { useState } from "react";
import lock from "../../assets/img/Lock.svg";
import eye from "../../assets/img/eye-off.svg";


export default function Password() {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="password">
      <img src={lock} alt=""/>
      <input name="password" type={passwordShown ? "text" : "password"} placeholder="Password"/>
      <button onClick={togglePassword} className={passwordShown ? "hide-pass" : "show-pass"}><img src={eye} alt=""/></button>
    </div>
  );
}