
import parse from "html-react-parser";
import { SubTaskPrompt } from "../../interfaces/Task";
import { Media } from "../../interfaces/Media";
import MediaPreview from "../../elements/MediaPreview";
import { Table } from "react-bootstrap";
import { replaceOembed } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";

export interface SubTaskPromptPreviewProps {
    task_id: string;
    subtask_id: string;
    prompt: SubTaskPrompt;
}

export default function SubTaskPromptPreview(props: SubTaskPromptPreviewProps) {
    const navigate = useNavigate();
    const handleChangeShare = (e: any) => {
        const body = { share: e.target.checked };
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body)
        };
        const reqUrl = 'https://srv.taskgen.eu/v1' +
            '/task/' + props.task_id +
            '/subtask/' + props.subtask_id +
            '/prompt/' + props.prompt._id + 
            '/media/' + e.target.value + '/share';
        fetch(reqUrl, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {});
    }

    return(
        <div className="sub-task-prompt-preview">
            <div className="inner">
                <div className="prompt-name">
                    <strong>{ props.prompt.name }</strong>
                </div>
                <div className="prompt-content">
                    <div className={ 'instructions' + (props.prompt.media.length == 0 ? ' no-medias' : '')}>
                        { props.prompt.instructions &&
                            <div className="inner">
                                <label><strong>{ 'Aim and instructions' }</strong></label>
                                <div className="value">{ parse(replaceOembed(props.prompt.instructions)) }</div>
                            </div>
                        }
                    </div>
                    { props.prompt.media.length > 0 &&
                        <div className="medias-list">
                            <Table responsive={ true }>
                                <thead>
                                    <tr>
                                        <th>{ 'Resource' }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { props.prompt.media.map((m: Media) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <MediaPreview media={ m } />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>                                        
                        </div>
                    }
                    <div className="text">
                        { !props.prompt.text && 
                            <div className="message">
                                { 'This prompt has no text '}
                            </div>
                        }
                        { props.prompt.text &&
                            <div className="inner">
                                <label><strong>{ 'Text' }</strong></label>
                                <div className="value">{ parse(replaceOembed(props.prompt.text)) }</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}