import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import logo from './logo.svg';

const maintenance = false;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    { maintenance && 
      <div className="maintenance-message">
        <img className="logo" alt="Task-Gen" src= {logo}/>
        <p>
          { 'We are working to improve the Taskgen site.' }
          <br />
          { 'It will be active again shortly.' }
        </p>
        <p>
          { 'Thank you for your patience.' }
        </p>
      </div>
    }
    { !maintenance && <App />}
  </React.StrictMode>
);
