import { useState } from "react";
import { SubTaskPrompt, SubTaskRole, SubTaskStudent } from "../../interfaces/Task";
import Select from "react-select";

export interface SiteSubTaskStudentProps {
    student: SubTaskStudent;
    saveCallback: CallableFunction;
    editCallback: CallableFunction;
    deleteCallback: CallableFunction;
    roles: SubTaskRole[];
    prompts: SubTaskPrompt[]
}

export default function SiteSubTaskStudent(props: SiteSubTaskStudentProps) {
    const [showEdit, setShowEdit] = useState(false);

    const handleShowEdit = () => {
        props.editCallback();
        //setShowEdit(true);
    }

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete the student? This action cannot be undone.')) {
            props.deleteCallback(props.student);
        }
    }

    const getRoleName = (position: number) => {
        const role = props.roles[position];
        if (role === undefined) {
            return '';
        } else {
            return role.name;
        }
    }

    const getPromptName = (position: number) => {
        const prompt = props.prompts[position];
        if (prompt === undefined) {
            return '';
        } else {
            return prompt.name;
        }
    }

    return(
        <div className="site-sub-task-student-container" key={ 'sub-task-student-' + props.student._id }>
            <div className="student-data">
                <div className="actions">
                    <a className="edit-link" title={ 'Edit student' } onClick={ handleShowEdit }></a>
                    <a className="delete-link" title={ 'Delete student' } onClick={ handleDelete }></a>
                </div>
                <div className="name text-truncate">
                    { props.student.name }
                </div>
                <div className="meta">
                    { getRoleName(props.student.role).length > 0 &&
                        <div className="role text-truncate">
                            { 'R:'} { getRoleName(props.student.role) }
                        </div>
                    }
                    { getPromptName(props.student.prompt).length > 0 &&
                        <div className="prompt text-truncate">
                            { 'P:'} { getPromptName(props.student.prompt) }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}