import { Button, Form } from "react-bootstrap";
import { User } from "../../interfaces/User";
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";

export interface UserDeleteProps {
    user: User;
    submitCallback: CallableFunction;
    closeCallback: CallableFunction;
}

export default function UserDelete(props: UserDeleteProps) {
    const navigate = useNavigate();
    const handleClose = () => {
        props.closeCallback();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const requestOptionsDelete = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/user/' + props.user._id, requestOptionsDelete)
            .then(response => {
                props.submitCallback(props.user._id);
            })
            .catch(error => {
                navigate('/');
            });
    }

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ handleClose }></a>
                    <div className="admin-delete-user-container">
                        <h4>{ 'Delete user' }</h4>
                        <Form onSubmit={ handleSubmit }>
                            <Form.Text id="deleteConfirmationMessage">
                                { parse('You are about to delete user with email <strong>' + props.user.email + '</strong>. ' +
                                    'All user data and tasks will be deleted. This action cannot be undone. ' +
                                    'Are you sure you want to proceed?')
                                }
                            </Form.Text>
                            <div className="actions">
                                <Button variant="primary" type="submit">{ 'Yes' }</Button>
                                <Button variant="secondary" onClick={ handleClose }>{ 'No' }</Button>
                            </div>
                        </Form>
                    </div>
                </div>  
            </div>
        </div>
    );
}