import { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useNavigate } from "react-router-dom";

export default function ExportTasks() {
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem('isAdmin');
    
    const tableRef = useRef(null);
    
    const [tasks, setTasks] = useState<any[]>([]);

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/stats/tasks', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setTasks(data);
            });
    }, []);

    return (
        <div>
                <DownloadTableExcel
                    filename="tasks"
                    sheet="tasks"
                    currentTableRef={tableRef.current}
                >

                   <button>Export Tasks</button>

                </DownloadTableExcel>

                <table  ref={tableRef}>
                 <tbody>
                    <tr>
                        <th>user_id</th>
                        <th>name</th>
                        <th>num_pretasks</th>
                        <th>num_subtasks</th>
                        <th>num_postasks</th>
                        <th>num_sessions</th>
                        <th>aims</th>
                        <th>proficiency_level</th>
                        <th>clonned</th>
                        <th>original_source</th>
                        <th>original_task</th>
                    </tr>
                    { tasks.map((t: any) => {
                        return <tr>
                            <td>{ t.user_id }</td>
                            <td>{ t.name }</td>
                            <td>{ t.num_pretasks }</td>
                            <td>{ t.num_subtasks }</td>
                            <td>{ t.num_postasks }</td>
                            <td>{ t.num_sessions }</td>
                            <td>{ t.aims.join('|') }</td>
                            <td>{ t.proficiency_level.join('|') }</td>
                            <td>{ t.clonned }</td>
                            <td>{ t.original_source }</td>
                            <td>{ t.original_task }</td>
                        </tr>;
                    })}
                  </tbody>
                </table>

            </div>);
}