
import parse from "html-react-parser";
import { PosTask, TaskSession } from "../../interfaces/Task";
import { Media } from "../../interfaces/Media";
import MediaPreview from "../../elements/MediaPreview";
import { Form, Table } from "react-bootstrap";
import { replaceOembed } from "../../../utils/utils";
import Moment from 'moment';
import { useNavigate } from "react-router-dom";

export interface PreviewPosTaskProps {
    task_id: string;
    postask: PosTask;
    sessions: TaskSession[];
}

export default function PreviewPosTask(props: PreviewPosTaskProps) {
    const navigate = useNavigate();

    const handleChangeShare = (e: any) => {
        const body = { share: e.target.checked };
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body)
        };
        const reqUrl = 'https://srv.taskgen.eu/v1' +
            '/task/' + props.task_id +
            '/postask/' + props.postask._id +
            '/media/' + e.target.value + '/share';
        fetch(reqUrl, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {});
    }

    const handleCalendarChange = (event: any) => {
        const body = { session: event.target.value };
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body)
        };
        const reqUrl = 'https://srv.taskgen.eu/v1' +
            '/task/' + props.task_id +
            '/postask/' + props.postask._id +
            '/session';
        fetch(reqUrl, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {});
    }

    const handleStudentsChange = (event: any) => {
        const body = { students: event.target.checked };
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body)
        };
        const reqUrl = 'https://srv.taskgen.eu/v1' +
            '/task/' + props.task_id +
            '/postask/' + props.postask._id +
            '/students';
        fetch(reqUrl, requestOptionsPost)
            .then(response => response.json())
            .then(data => {});
    }

    const handleTeachersChange = (event: any) => {
        const body = { teachers: event.target.checked };
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body)
        };
        const reqUrl = 'https://srv.taskgen.eu/v1' +
            '/task/' + props.task_id +
            '/postask/' + props.postask._id +
            '/teachers';
        fetch(reqUrl, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {});
    }

    return(
        <div className="postask-preview">
            <div className="inner">
                <div className="postask-name">
                    <strong>{ props.postask.name }</strong>
                </div>
                <div className="postask-options">
                    <Form.Group controlId="calendar" className="calendar">
                        <Form.Select name="session" onChange={ handleCalendarChange }>
                            <option value="">{ '- Select a session -' }</option>
                            { props.sessions.map((s: TaskSession) => {
                                return <option selected={ props.postask.session == s._id } value={ s._id }>{ s.name + ' (' + Moment(s.datetime).format('DD/MM/YYYY') + ')' }</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="field-student-share">
                        <Form.Check type="checkbox" id={ 'share-with-students-' + props.postask._id } 
                            key={ 'share-with-students-' + props.postask._id } label={ 'Students' }
                            onChange={ handleStudentsChange } defaultChecked={ props.postask.students }
                        />
                    </Form.Group>
                    <Form.Group className="field-teachers-share">
                        <Form.Check type="checkbox" id={ 'share-with-teachers-' + props.postask._id } 
                            key={ 'share-with-teachers-' + props.postask._id } label={ 'Teachers' }
                            onChange={ handleTeachersChange } defaultChecked={ props.postask.teachers }
                        />
                    </Form.Group>
                </div>
                <div className="postask-content">
                    <div className={ 'instructions' + (props.postask.media.length == 0 ? ' no-medias' : '')}>
                        { props.postask.instructions &&
                            <div className="inner">
                                <label><strong>{ 'Aim and instructions' }</strong></label>
                                <div className="value">{ parse(replaceOembed(props.postask.instructions)) }</div>
                            </div>
                        }
                    </div>
                    { props.postask.media.length > 0 &&
                        <div className="medias-list">
                            <Table responsive={ true }>
                                <thead>
                                    <tr>
                                        <th>{ 'Resource' }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { props.postask.media.map((m: Media) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <MediaPreview media={ m } />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    }
                    <div className="text">
                        { props.postask.text &&
                            <div className="inner">
                                <label><strong>{ 'Text' }</strong></label>
                                <div className="value">{ parse(replaceOembed(props.postask.text)) }</div>
                            </div>
                        }
                        { !props.postask.instructions &&
                            <div className="message">
                                { 'This post-task has no text' }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}