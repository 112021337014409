import { Button } from "react-bootstrap";
import { Task } from "../interfaces/Task";
import { useNavigate } from "react-router-dom";

interface DeleteTaskProps {
    task: Task,
    deleteCallback: CallableFunction;
    closeCallback: CallableFunction;
}


export default function DeleteTask(props: DeleteTaskProps) {
    const navigate = useNavigate();
    const handleDelete = () => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/task/' + props.task._id, requestOptions)
            .then(response => {
                props.deleteCallback();
            })
            .catch(error => {
                navigate('/');
            });
    }

    const handleClose = () => {
        props.closeCallback();
    }

    return (
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <div className="delete-task-container confirmation-dialog">
                        <div className="inner">
                            <h2>{ 'Delete task' }</h2>
                            <p>
                                You are about to delete the task <strong>{ props.task.name }</strong>. This action cannot be undone.
                            </p>
                            <div className="actions">
                                <Button className="btn btn-primary" onClick={ handleDelete }>{ 'Delete' }</Button>
                                <Button className="btn btn-warning" onClick={ handleClose }>{ 'Cancel' }</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}