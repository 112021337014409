import { SubTaskPrompt } from "../../interfaces/Task";

export interface SubTaskPromptItemProps {
    prompt: SubTaskPrompt;
    editPrompt: CallableFunction;
    deletePrompt: CallableFunction;
    clonePrompt: CallableFunction;
    position: number;
}

export default function SubTaskPromptItem(props: SubTaskPromptItemProps) {
    const handleEditPrompt = () => {
        props.editPrompt(props.prompt, props.position);
    }

    const handleDeletePrompt = () => {
        if (window.confirm('Are you sure you want to delete the prompt? This action cannot be undone.')) {
            props.deletePrompt(props.prompt, props.position);
        }
    }

    const handleClonePrompt = () => {
        props.clonePrompt(props.prompt);
    }

    return(
        <div className="sub-task-prompt-item-container" key={ 'sub-task-prompt-' + props.prompt._id }>
            <div className="name">{ props.prompt.name }</div>
            <div className="actions">
                <a className="clone-link" title={ 'Clone prompt' } onClick={ handleClonePrompt }></a>
                <a className="edit-link" title={ 'Edit prompt' } onClick={ handleEditPrompt }></a>
                <a className="delete-link" title={ 'Delete prompt' } onClick={ handleDeletePrompt }></a>
            </div>
        </div>
    );
}