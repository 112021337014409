import { useEffect, useState } from "react";
import { User } from "../interfaces/User";
import { useNavigate } from "react-router-dom";
import EditProfile from "./EditProfile";

export interface UserTermsProps {
    closeCallback: CallableFunction;
}

export default function UserTerms(props: UserTermsProps) {
    const handleClose = () => {
        props.closeCallback();
    }

    return(<div className="overlay">
                <div className="inner">
                    <div className="overlay-content">
                        <a className="close" onClick={ handleClose }></a>
                        <div className="overlay-terms">
                            <h4>{ 'Conditions of Use' }</h4>
                            <div className="terms-content">
                                <p><strong>{ 'Introduction'}</strong></p>
                                <p>The taskGen Project is a three-year research initiative supported by grant PID2020-119009RB-I00 from the Spanish Ministry of Science and Innovation (MCIN) and the National Research Agency (AEI) [Reference: 10.13039/501100011033]. At the University of Barcelona, we have developed the taskGen web-based tool for the automated design of second/foreign language communicative tasks. Participants engaging in the pilot evaluation through the taskGen platform are required to read and agree to the terms outlined below.</p>
                                <p><strong>Consent</strong></p>
                                <p>Participants engaging in this pilot evaluation of the taskGen tool implicitly agree to these terms upon logging into the platform. </p>
                                <ol>
                                    <li>
                                        <strong>Aim of this research project</strong>
                                        <p>The aim is to develop a web-based tool that will assist teachers in the design of second and foreign language tasks. The goal is for the tool to help teacher create, clone, share, download/upload onto teaching platforms, and to analyze texts for the automatic detection of linguistic features. Additionally, the tool can be used for training as it contains aids with information about task design.</p>
                                    </li>
                                    <li>
                                        <strong>Kind participation</strong>
                                        <p>During implementation users will be able to design, organize, store and share tasks with other users within the taskGen system. Participation may be stopped at any point. Participants can eliminate their accounts and leave the system.</p>
                                    </li>
                                    <li>
                                        <strong>Types of data to be processed</strong>
                                        <p>During the implementation of the pilot, the tool will process (retrieve, store, produce, relate, analyze and use automatically):</p>
                                        <ol>
                                            <li>From your profile data: educational context, teaching modality, teaching approach, country, years of experience. No information related to your identity will be processed, analysed or shared.</li>
                                            <li>System use data: timestamps of platform interaction, mouse-click tracking, text length, choice of pre-task and post-task types, choice of text editing and analysis tools, design options, and use of aids.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>Database Security</strong>
                                        <p>Collected data will be securely stored, with access limited to authorized personnel only. Stringent security measures will be employed to protect confidentiality.</p>
                                    </li>
                                    <li>
                                        <strong>Data retrieval and treatment</strong>
                                        <p>Standard and secure data analytics tools will be used to retrieve and analyze the collected data exclusively for research purposes.</p>
                                        <ol>
                                            <li>
                                                <strong>Objectives and legal basis for the processing and treatment of data</strong>
                                                <p>The processing and treatment of the data follows the principles of the data protection law (GDPR) 2016-679 of the European Union as well as the Catalan Agency of Data Protection.</p>
                                            </li>
                                            <li>
                                                <strong>Data treatment</strong>
                                                <p>Data will be stored until four years after the end of the project. The treatment of personal data is carried out at/by the General Secretary of Universitat de Barcelona, Gran Via de les Corts Catalanes, 585, 08007 Barcelona. The email address is secretaria.general@ub.edu. You can revoke your consent at any point by contacting the email address above.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>Research Purposes and confidentiality</strong>
                                        <p>The collected data will be used solely for research purposes, enhancing understanding, improving user experience, and informing project development. The results of the project will appear in academic publications and organizations (e.g. Ministry of Education, statistical services, etc.) or in the media with no reference to any data related to participants’ personal information, hence ensuring security, privacy and anonymity. No commercial utilization or third-party sharing will occur.</p>
                                    </li>
                                    <li>
                                        <strong>Ethical Compliance</strong>
                                        <p>The data collection process strictly adheres to ethical standards as set by the University of Barcelona, ensuring responsible handling of information throughout collection, storage, and analysis.</p>
                                    </li>
                                    <li>
                                        <strong>Modification of Terms</strong>
                                        <p>Terms may be modified during the project, and participants will be notified of any changes.</p>
                                    </li>
                                </ol>
                                <p>By utilizing the platform, participants acknowledge their understanding and agreement with these terms. Failure to comply may result in access termination.</p>
                                <p>If you consider that your rights have not been adequately addressed, you can notify the UB Data Protection Delegate: protecciodedades@ub.edu or Travessera de les Corts, 131-159, Pabellón Rosa, 08028 Barcelona.</p>
                                <p>Likewise, you have the right to file a claim with the Catalan Data Protection Authority.</p>
                                <p>If you have any questions, you can contact the taskGen team at<br />taskgenproject@gmail.com</p>
                                <p>By accepting these terms and conditions, I hereby certify that I have permission to use and release the materials included in my task designs. I am responsible for the materials that I will be sharing with other users within taskGen.</p>
                                <p>The taskGen team<br />University of Barcelona</p>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        );
}