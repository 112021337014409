import { Component } from "react";
import Help from "../elements/HelpContent";

export default class IntroMessage extends Component {
    render() {
        return(
            <div className="intro-message box">
                <h2>What is <span>taskGen</span></h2>
                <div className="message">
                    <p>It is an ongoing three-year research project made possible by a grant PID2020-119009RB-I00 funded by the Ministry of Science and Innovation (MCIN) and National Research Agency (AEI) 10.13039/501100011033. </p>
                    <p>In this project we are developing and piloting a tool that will help teachers and task and syllabus designers with organizing and automating the design of second/foreign language communicative tasks. If you wish to know more about the project, please click here... <a href="https://taskgenresearchgroup.wordpress.com/" title="Read more">Read more</a></p>
                </div>
            </div>
        )
    }
}
