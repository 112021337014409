import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from '../../logo.svg';

export default function PreviewHeader() {
    return(
        <div className="preview-header">
            <Container>
                <Row>
                    <Col>
                        <img className="logo" alt="Task-Gen" src= {logo}/>
                    </Col>
                    <Col>
                        
                    </Col>
                </Row>
            </Container>
            
        </div>
    )
}