import { useEffect, useState } from "react";
import PrimaryHeader from "../../header/PrimaryHeader";
import SecondaryHeader from "../../header/SecondaryHeader";
import { Table } from "react-bootstrap";
import { Feedback, emptyFeedback } from "../../interfaces/Feedback";
import Moment from 'moment';
import { useNavigate } from "react-router-dom";

export default function Feedbacks() {
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem('isAdmin');
    
    const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/feedback', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setFeedbacks(data);
            });
    }, []);



    return (
        <div className="page">
            <SecondaryHeader />
            <div className="page-content">
                <PrimaryHeader />
                <div className="page-content-inner">
                    <div className="row">
                        { isAdmin &&
                            <div className="admin-feedbacks-container">
                                <h1>Feedback management</h1>
                                <div className="admin-feedbacks-table-container">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Issue</th>
                                                <th>Section</th>
                                                <th>Body</th>
                                                <th>Date</th>
                                                <th>User</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { feedbacks.map((f: Feedback) => {
                                                if (f.user != null) {
                                                    return(
                                                        <tr key={ 'feedback-' + f._id }>
                                                            <td>{ f.issue }</td>
                                                            <td>{ f.section }</td>
                                                            <td>{ f.body }</td>
                                                            <td>{ Moment(f.date).format('DD/MM/YYYY hh:mm') }</td>
                                                            <td>{ f.user.email }</td>
                                                            <td></td>
                                                        </tr>
                                                    );
                                                }
                                            })}
                                        </tbody>
                                        </Table>
                                </div>
                            </div>
                        }
                        { !isAdmin &&
                            <div className="admin-feedbacks-container">
                                { 'You are not allowed to access this section.' }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>);
}