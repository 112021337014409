import { Component } from "react";

export default class Beginnings extends Component {
    render() {
        return(
            <div className="beginnings box">
                <h2>{ 'Beginnings are always difficult' }</h2>
                <div className="message margin-bottom-small">
                    { 'Do not hesitate to contact us with any questions you may have.' }
                </div>
                <div className="actions">
                    <a className="btn btn-primary" href="mailto:taskgenproject@gmail.com">
                        { 'Contact us' }
                    </a>
                </div>
            </div>
        )
    }
}