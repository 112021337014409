import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useNavigate } from "react-router-dom";

export default function ExportNlpEvents() {
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem('isAdmin');
    
    const tableRef = useRef(null);
    
    const [events, setEvents] = useState<any[]>([]);

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/stats/usage-nlp', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setEvents(data);
            });
    }, []);

    return (
        <div>
                <DownloadTableExcel
                    filename="nlp-events"
                    sheet="events"
                    currentTableRef={tableRef.current}
                >

                   <button>Export NLP Events</button>

                </DownloadTableExcel>

                <table  ref={tableRef}>
                 <tbody>
                    <tr>
                        <th>email</th>
                        <th>group</th>
                        <th>subgroup</th>
                        <th>option</th>
                        <th>timestamp</th>
                    </tr>
                    { events.map((e: any) => {
                        return <tr>
                            <td>{ e.user }</td>
                            <td>{ e.group }</td>
                            <td>{ e.subgroup }</td>
                            <td>{ e.option }</td>
                            <td>{ moment(e.start).format('YYYY-MM-DD hh:mm:ss') }</td>
                        </tr>;
                    })}
                  </tbody>
                </table>

            </div>);
}