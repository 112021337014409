import { useState } from "react";
import { SubTaskPrompt, SubTaskRole, SubTaskStudent } from "../../interfaces/Task";
import Select from "react-select";

export interface SiteSubTaskStudentProps {
    student: SubTaskStudent;
    saveCallback: CallableFunction;
    cancelCallback: CallableFunction;
    roles: SubTaskRole[];
    prompts: SubTaskPrompt[]
}

export default function SiteSubTaskStudentEdit(props: SiteSubTaskStudentProps) {
    const getRole = (position: number) => {
        const role = props.roles[position];
        if (role == undefined) {
            return {value: '', label: '- Select a role -'};
        } else {
            return {value: props.roles[position]._id, label: props.roles[position].name};
        }
    }

    const getPrompt = (position: number) => {
        const prompt = props.prompts[position];
        if (prompt == undefined) {
            return {value: '', label: '- Select a prompt -'};
        } else {
            return {value: props.prompts[position]._id, label: props.prompts[position].name};
        }
    }

    const [name, setName] = useState(props.student.name);
    const [role, setRole] = useState(props.student.role);
    const [roleValue, setRoleValue] = useState<any>(getRole(props.student.role));
    const [prompt, setPrompt] = useState<any>(props.student.prompt);
    const [promptValue, setPromptValue] = useState<any>(getPrompt(props.student.prompt));

    const roleOptions = [
        {value: '', label: '- Select a role -'},
        ...props.roles.map((r: SubTaskRole) => {
            return {
                value: r._id,
                label: r.name
            }
        })
    ];

    const promptOptions = [
        {value: '', label: '- Select a prompt -'},
        ...props.prompts.map((p: SubTaskPrompt) => {
            return {
                value: p._id,
                label: p.name
            }
        })
    ];

    const handleNameChange = (e: any) => {
        setName(e.target.value);
    }

    const save = () => {
        const newStudent = Object.assign({}, props.student);
        newStudent.name = name;
        newStudent.role = role;
        newStudent.prompt = prompt;
        props.saveCallback(newStudent);
    }

    const cancel = (e: any) => {
        props.cancelCallback();
    }

    const handleRoleChange = (e: any) => {
        const position = props.roles.findIndex((r: SubTaskRole) => r._id == e.value);
        setRole(position);
        if (position == -1) {
            setRoleValue(roleOptions[0]);
        } else {
            setRoleValue(roleOptions[position + 1]);
        }
    }

    const handlePromptChange = (e: any) => {
        const position = props.prompts.findIndex((r: SubTaskPrompt) => r._id == e.value);
        setPrompt(position);
        if (position == -1) {
            setPromptValue(promptOptions[0]);
        } else {
            setPromptValue(promptOptions[position + 1]);
        }
    }

    return(
        <div className="site-sub-task-student-edit-container">
            <div className="edit-controls">
                <h4 className="text-truncate">{ 'Editing student' } <span>{ name }</span></h4>
                <input type="text" defaultValue={ props.student.name } onChange={ handleNameChange } />
                <Select className="role" options={ roleOptions } onChange={ handleRoleChange } value={ roleValue } />
                <Select className="prompt" options={ promptOptions } onChange={ handlePromptChange } value={ promptValue }   />
                <div className="edit-actions">
                    <button className="btn btn-primary" title={ 'Save' } onClick={ save }>{ 'Save' }</button>
                    <button className="btn btn-secondary" title={ 'Cancel' } onClick={ cancel }>{ 'Cancel' }</button>
                </div>
            </div>
        </div>
    );
}