import { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from '../../logo.svg';
import UserTeaser from '../user/UserTeaser';
import { Link } from "react-router-dom";

export default class PrimaryHeader extends Component{
    role = localStorage.getItem('userRole');

    render() {
        return(
            <div className="primary-header">
                <Container>
                    <Row>
                        <Col md={3}>
                            <Link className="nav-link" to="/dashboard"><img className="logo" alt="Task-Gen" src= {logo}/></Link>
                        </Col>
                        <Col md={6}>
                            <ul className="primary-header-menu">
                                { this.role && (this.role == 'admin' || this.role?.includes('admin')) &&
                                    <li>
                                        <Link className="admin-orgs" to="/admin/orgs">{ 'Organizations' }</Link>
                                    </li>
                                }
                                { this.role && (this.role == 'admin' || this.role?.includes('admin')) &&
                                    <li>
                                        <Link className="admin-users" to="/admin/users">{ 'Users' }</Link>
                                    </li>
                                }
                                { this.role && (this.role == 'admin' || this.role?.includes('admin')) &&
                                    <li>
                                        <Link className="admin-help" to="/admin/help">{ 'Manage help' }</Link>
                                    </li>    
                                }
                                { this.role && (this.role == 'admin' || this.role?.includes('admin')) &&
                                    <li>
                                        <Link className="admin-faqs" to="/admin/faqs">{ 'Manage FAQs' }</Link>
                                    </li>    
                                }
                                { this.role && (this.role == 'admin' || this.role?.includes('admin')) &&
                                    <li>
                                        <Link className="admin-feedbacks" to="/admin/feedbacks">{ 'Feedbacks' }</Link>
                                    </li>    
                                }
                            </ul>
                            
                        </Col>
                        <Col md={3}>
                            <UserTeaser />
                        </Col>
                    </Row>
                </Container>
                
            </div>
        )
    }
}
