export interface HelpItem {
    _id: string,
    title: string,
    teaser: string,
    body: string,
    examples: string,
    references: string
}

export const emptyHelpItem = {
    _id: '',
    title: '',
    teaser: '',
    body: '',
    examples: '',
    references: ''
}

export interface Help {
    _id: string,
    name: string,
    items: HelpItem[],
    weight: number
}

export const emptyHelp: Help = {
    _id: '',
    name: '',
    items: [],
    weight: 0
}
