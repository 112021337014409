import { useEffect, useState } from "react";
import logo from '../../logo.svg';
import close from '../../assets/img/x.svg';
import { Link, useNavigate } from "react-router-dom";
import { Container, Form } from "react-bootstrap";
import { countryListAllIsoData, teachingFrameworks, teachingLocations, teachingModels } from "../../utils/data/Options";
import { makeSafeForCSS } from "../../utils/utils";
import Select from 'react-select'
import { User, emptyUser } from "../interfaces/User";
import UserTerms from "./UserTerms";

const countryOptions = countryListAllIsoData.map(
    (c: any) => {
        return({value: c.code, label: c.name})
    }
);

const FirstLoginForm = () => {
    const userId = localStorage.getItem('userId');
    const [loaded, setLoaded] = useState(false);
    const [teaching_years, setTeachingYears] = useState(0);
    const [teaching_location, setTeachingLocation] = useState<string[]>([]);
    const [otherTeachingLocation, setOtherTeachingLocation] = useState('');
    const [teaching_model, setTeachingModel] = useState<string[]>([]);
    const [otherTeachingModel, setOtherTeachingModel] = useState('');
    const [teaching_framework, setTeachingFramework] = useState<string[]>([]);
    const [otherTeachingFramework, setOtherTeachingFramework] = useState('');
    const [tblt, setTblt] = useState(false);
    const [tbltHours, setTbltHours] = useState(0);
    const [country, setCountry] = useState<any>();
    const [user, setUser] = useState<User>(emptyUser);
    const [showTerms, setShowTerms] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (teaching_location.length == 0 && event.target.otherTeachingLocation.value.length == 0) {
            alert('Please, tell us where do you usually teach.');
            return;
        }
        if (teaching_model.length == 0 && event.target.otherTeachingModel.value.length == 0) {
            alert('Please, tell us how do you typically teach.');
            return;
        }
        if (teaching_framework.length == 0 && event.target.otherTeachingFramework.value.length == 0) {
            alert('Please, tell us your general teaching framework.');
            return;
        }

        const teachingLocation = event.target.otherTeachingLocation.value.length == 0 ?
            teaching_location : [...teaching_location, event.target.otherTeachingLocation.value];

        const teachingModel = event.target.otherTeachingModel.value.length == 0 ?
            teaching_model : [...teaching_model, event.target.otherTeachingModel.value];

        const teachingFramwork = event.target.otherTeachingFramework.value.length == 0 ?
            teaching_framework : [...teaching_framework, event.target.otherTeachingFramework.value];

        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                privacy_policy: event.target.privacy_policy.checked,
                profile: {
                    firstname: event.target.firstname.value,
                    lastname: event.target.lastname.value,
                    teaching_location: teachingLocation,
                    teaching_model: teachingModel,
                    teaching_framework: teachingFramwork,
                    country: country.value,
                    region: event.target.region.value,
                    teaching_years: event.target.teaching_years.value,
                    level: '',
                    level_ask_again: false,
                    tblt: event.target.tblt.checked,
                    tblt_hours: event.target.tblt.checked ? tbltHours : 0
                }
            })
        };
        fetch('https://srv.taskgen.eu/v1/user/profile/' + userId, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                navigate('/dashboard');
            });
    }

    const handleTeachingLocationChange = (e: any) => {
        let newTeachingLocation: string[] = [];
        const index = teaching_location.findIndex(l => l === e.target.value);
        if (e.target.checked) {
            if (index === -1) {
                newTeachingLocation = [
                    ...teaching_location,
                    e.target.value
                ];
            }
        } else {
            newTeachingLocation = teaching_location.filter(l => l !== e.target.value)
        }
        setTeachingLocation(newTeachingLocation);
    }

    const handleTeachingModelChange = (e: any) => {
        let newTeachingModel: string[] = [];
        const index = teaching_model.findIndex(m => m === e.target.value);
        if (e.target.checked) {
            if (index === -1) {
                newTeachingModel = [
                    ...teaching_model,
                    e.target.value
                ];
            }
        } else {
            newTeachingModel = teaching_model.filter(m => m !== e.target.value)
        }
        setTeachingModel(newTeachingModel);
    }

    const handleTeachingFrameworkChange = (e: any) => {
        let newTeachingFramework: string[] = [];
        const index = teaching_framework.findIndex(m => m === e.target.value);
        if (e.target.checked) {
            if (index === -1) {
                newTeachingFramework = [
                    ...teaching_framework,
                    e.target.value
                ];
            }
        } else {
            newTeachingFramework = teaching_framework.filter(m => m !== e.target.value)
        }
        setTeachingFramework(newTeachingFramework);
    }

    const handleSetCountry = (e: any) => {
        setCountry(e);
    }

    const handleChangeTeachingYears = (e: any) => {
        setTeachingYears(e.value);
    }

    useEffect(() => {
        const requestOptionsGet = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/user/' + userId, requestOptionsGet)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                if (data.profile.teaching_location !== undefined) {
                    const otherTeachingLocations: string[] = [];
                    const newTeachingLocations: string[] = [];
                    for (let i = 0; i < data.profile.teaching_location.length; i++) {
                        if (teachingLocations.findIndex((tl: any) => tl.value == data.profile.teaching_location[i]) == -1) {
                            otherTeachingLocations.push(data.profile.teaching_location[i]);
                        } else {
                            newTeachingLocations.push(data.profile.teaching_location[i]);
                        }
                    }
                    setOtherTeachingLocation(otherTeachingLocations.join(','));
                    setTeachingLocation(newTeachingLocations);
                }

                if (data.profile.teaching_model !== undefined) {
                    const otherTeachingModels: string[] = [];
                    const newTeachingModels: string[] = [];
                    for (let i = 0; i < data.profile.teaching_model.length; i++) {
                        if (teachingModels.findIndex((tl: any) => tl.value == data.profile.teaching_model[i]) == -1) {
                            otherTeachingModels.push(data.profile.teaching_model[i]);
                        } else {
                            newTeachingModels.push(data.profile.teaching_model[i]);
                        }
                    }
                    setOtherTeachingModel(otherTeachingModels.join(','));
                    setTeachingModel(newTeachingModels);
                }

                if (data.profile.teaching_framework !== undefined) {
                    const otherTeachingFrameworks: string[] = [];
                    const newTeachingFrameworks: string[] = [];
                    for (let i = 0; i < data.profile.teaching_framework.length; i++) {
                        if (teachingFrameworks.findIndex((tl: any) => tl.value == data.profile.teaching_framework[i]) == -1) {
                            otherTeachingFrameworks.push(data.profile.teaching_framework[i]);
                        } else {
                            newTeachingFrameworks.push(data.profile.teaching_framework[i]);
                        }
                    }
                    setOtherTeachingFramework(otherTeachingFrameworks.join(','));
                    setTeachingFramework(newTeachingFrameworks);
                }

                if (data.profile.country !== undefined) {
                    setCountry(countryOptions.find((c: any) => c.value == data.profile.country));
                }

                setTblt(data.profile.tblt);
                setTbltHours(data.profile.tblt_hours);
                setTeachingYears(data.profile.teaching_years);

                setUser(data);
                setLoaded(true);
            }); 
    }, []);

    const handleTbltChange = (e: any) => {
        setTblt(e.target.checked);
    }

    const handleTbltHoursChange = (e: any) => {
        setTbltHours(e.target.value);
    }

    const handleShowTerms = () => {
        setShowTerms(true);
    }

    const handleHideTerms = () => {
        setShowTerms(false);
    }

    return(
        <Form className="form-first-login" onSubmit={ handleSubmit }>
            <div className="inner">
                <div className="row">
                    <div className="col-xs-12 col-md-6 field">
                        <input type="text" name="firstname" placeholder="First name(s)" required
                            defaultValue={ user.profile.firstname === undefined ? '' : user.profile.firstname } />
                    </div>
                    <div className="col-xs-12 col-md-6 field">
                        <input type="text" name="lastname" placeholder="Family name(s)" required
                            defaultValue={ user.profile.lastname === undefined ? '' : user.profile.lastname } />
                    </div>
                    <div className="where-input col-12">
                        <p className="label">Where do you usually teach?</p>
                        <div className="checkboxes horizontal other">
                            <div className="checkbox" key={ makeSafeForCSS('University') }>
                                <Form.Check type="checkbox" name="teachingLocation1"
                                    value={ 'University' } onChange={ handleTeachingLocationChange }
                                    label={ 'University' }
                                    checked={ teaching_location === undefined ? false : (teaching_location.indexOf('University') !== -1) } />
                            </div>
                            <div className="checkbox" key={ makeSafeForCSS('Secondary school') }>
                                <Form.Check type="checkbox" name="teachingLocation2"
                                    value={ 'Secondary school' } onChange={ handleTeachingLocationChange }
                                    label={ 'Secondary school' }
                                    checked={ teaching_location === undefined ? false : (teaching_location.indexOf('Secondary school') !== -1) } />
                            </div>
                            <div className="checkbox" key={ makeSafeForCSS('Primary school') }>
                                <Form.Check type="checkbox" name="teachingLocation3"
                                    value={ 'Primary school' } onChange={ handleTeachingLocationChange }
                                    label={ 'Primary school' }
                                    checked={ teaching_location === undefined ? false : (teaching_location.indexOf('Primary school') !== -1) } />
                            </div>
                            <div className="checkbox" key={ makeSafeForCSS('Private tutoring') }>
                                <Form.Check type="checkbox" name="teachingLocation5"
                                    value={ 'Private tutoring' } onChange={ handleTeachingLocationChange }
                                    label={ 'Private tutoring' }
                                    checked={ teaching_location === undefined ? false : (teaching_location.indexOf('Private tutoring') !== -1) } />
                            </div>
                            <div className="checkbox" key={ makeSafeForCSS('Online private tutoring') }>
                                <Form.Check type="checkbox" name="teachingLocation6"
                                    value={ 'Online private tutoring' } onChange={ handleTeachingLocationChange }
                                    label={ 'Online private tutoring' }
                                    checked={ teaching_location === undefined ? false : (teaching_location.indexOf('Online private tutoring') !== -1) } />
                            </div>
                            <div className="checkbox" key={ makeSafeForCSS('Language school') }>
                                <Form.Check type="checkbox" name="teachingLocation7"
                                    value={ 'Language school' } onChange={ handleTeachingLocationChange }
                                    label={ 'Language school' }
                                    checked={ teaching_location === undefined ? false : (teaching_location.indexOf('Language school') !== -1) } />
                            </div>
                            <div className="checkbox" key={ makeSafeForCSS('I am not teaching yet') }>
                                <Form.Check type="checkbox" name="teachingLocation8"
                                    value={ 'I am not teaching yet' } onChange={ handleTeachingLocationChange }
                                    label={ 'I am not teaching yet' }
                                    checked={ teaching_location === undefined ? false : (teaching_location.indexOf('I am not teaching yet') !== -1) } />
                            </div>
                            <div className="other" key="other">
                                <input type="text" name="otherTeachingLocation" placeholder="Other..."
                                    defaultValue={ otherTeachingLocation } />
                            </div>
                        </div>
                    </div>
                    <div className="how-input col-12">
                        <p className="label">How do you typically teach?</p>
                        <div className="checkboxes horizontal other">
                            <Form.Check type="checkbox" name="teachingModel1" key={ makeSafeForCSS('ace to Face') }
                                value={ 'Face to Face' } onChange={ handleTeachingModelChange }
                                label={ 'Face to Face' }
                                checked={ teaching_model === undefined ? false : teaching_model.indexOf('Face to Face') !== -1} />
                            <Form.Check type="checkbox" name="teachingModel2" key={ makeSafeForCSS('Fully online') }
                                value={ 'Fully online' } onChange={ handleTeachingModelChange }
                                label={ 'Fully online' }
                                checked={ teaching_model === undefined ? false : teaching_model.indexOf('Fully online') !== -1} />
                            <Form.Check type="checkbox" name="teachingModel3" key={ makeSafeForCSS('Hybrid/Blended') }
                                value={ 'Hybrid/Blended' } onChange={ handleTeachingModelChange }
                                label={ 'Hybrid/Blended' }
                                checked={ teaching_model === undefined ? false : teaching_model.indexOf('Hybrid/Blended') !== -1} />
                            <div className="other">
                                <input type="text" name="otherTeachingModel" placeholder="Other..."
                                    defaultValue={ otherTeachingModel } />
                            </div>
                        </div>
                    </div>
                    <div className="teaching-framework-input col-12">
                        <p className="label">{ 'What\'s your general teaching framework?' }</p>
                        <div className="checkboxes horizontal other">
                            <Form.Check type="checkbox" name="teaching-framework-tblt" key={ 'teaching-tblt' }
                                value={ 'tblt' } onChange={ handleTeachingFrameworkChange }
                                label={ 'Task Based Language Teaching' }
                                checked={ teaching_framework === undefined ? false : teaching_framework.indexOf('tblt') !== -1} />
                            <Form.Check type="checkbox" name="teaching-framework-clil" key={ 'teaching-clil' }
                                value={ 'clil' } onChange={ handleTeachingFrameworkChange }
                                label={ 'Content and Language Integrate Learning' }
                                checked={ teaching_framework === undefined ? false : teaching_framework.indexOf('clil') !== -1} />
                            <Form.Check type="checkbox" name="teaching-framework-pbl" key={ 'teaching-pbl' }
                                value={ 'pbl' } onChange={ handleTeachingFrameworkChange }
                                label={ 'Project-based Learning' }
                                checked={ teaching_framework === undefined ? false : teaching_framework.indexOf('pbl') !== -1} />
                            <Form.Check type="checkbox" name="teaching-framework-tt" key={ 'teaching-tt' }
                                value={ 'tt' } onChange={ handleTeachingFrameworkChange }
                                label={ 'Traditional Teaching' }
                                checked={ teaching_framework === undefined ? false : teaching_framework.indexOf('tt') !== -1} />
                            <Form.Check type="checkbox" name="teaching-framework-lsp" key={ 'teaching-lsp' }
                                value={ 'lsp' } onChange={ handleTeachingFrameworkChange }
                                label={ 'Language for Specific Purposes' }
                                checked={ teaching_framework === undefined ? false : teaching_framework.indexOf('lsp') !== -1} />
                            <Form.Check type="checkbox" name="teaching-framework-lap" key={ 'teaching-lap' }
                                value={ 'lap' } onChange={ handleTeachingFrameworkChange }
                                label={ 'Language for Academic Purposes' }
                                checked={ teaching_framework === undefined ? false : teaching_framework.indexOf('lap') !== -1} />
                            <Form.Check type="checkbox" name="teaching-framework-sn" key={ 'teaching-sn' }
                                value={ 'sn' } onChange={ handleTeachingFrameworkChange }
                                label={ 'Special Needs' }
                                checked={ teaching_framework === undefined ? false : teaching_framework.indexOf('sn') !== -1} />
                            <Form.Check type="checkbox" name="teaching-framework-emi" key={ 'teaching-emi' }
                                value={ 'emi' } onChange={ handleTeachingFrameworkChange }
                                label={ 'English as a Medium of Instruction' }
                                checked={ teaching_framework === undefined ? false : teaching_framework.indexOf('emi') !== -1} />    
                            <div className="other">
                                <input type="text" name="otherTeachingFramework" placeholder="Other..."
                                    defaultValue={ otherTeachingFramework } />
                            </div>
                        </div>
                    </div>
                    { loaded &&
                        <div className="tblt-input col-12">
                            <div className="radios horizontal other">
                                <Form.Check
                                    type="switch"
                                    id="tblt"
                                    label={ 'Have you ever trained using TBLT?' }
                                    onChange={ handleTbltChange }
                                    defaultChecked={ tblt }
                                />
                                { tblt &&
                                    <div className="tblt-hours">
                                        <input type="number" name="tbltHours" min={ 0 }
                                            value={ tbltHours } onChange={ handleTbltHoursChange } />
                                        <Form.Text className="tbltHoursDescription" muted>
                                            { 'Tell us for how long you have been teaching in TLBT' }
                                        </Form.Text>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className="col-xs-12 col-md-6">
                        <div className="field">
                            <Select options={countryOptions} onChange={ handleSetCountry } required
                                value={ country } />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="field">
                            <input type="text" name="region" placeholder="Region or State" required
                                defaultValue={ user.profile.region !== undefined ? user.profile.region : ''} />
                        </div>
                    </div>
                    <div className="field label-inline">
                        <label>How many years of teaching experience do you have?</label>
                        <input type="number" name="teaching_years" required min={ 0 }
                            value={ teaching_years } onChange={ handleChangeTeachingYears } />
                    </div>
                    <div className="terms-container">
                        <div className="accept-field">
                            { (user.privacy_policy === undefined || user.privacy_policy == false) &&
                                <input type="checkbox" required name="privacy_policy" />
                            }
                            { !(user.privacy_policy === undefined || user.privacy_policy == false) &&
                                <input type="checkbox" required name="privacy_policy" disabled={ true } checked />
                            }
                             { 'By clicking this box, I state that I have read and understood the ' }
                            <a onClick={ handleShowTerms }>{ 'terms and conditions' }</a>.
                        </div>
                    </div>
                    <div className="actions">
                        <input className="submit btn btn-primary" type="submit" value='Save'/>
                    </div>
                </div>
            </div>
            { showTerms && <UserTerms closeCallback={ handleHideTerms } /> }
        </Form>   
    );
}

export default function FirstLogin() {
    return(<><div className="red-background"></div>
        <div className="first-login-container">
            <div className="logo">
                <img alt="Task-Gen" src= {logo}/>
            </div>
            <div className="close-button"><Link to={"/"}><img src={close} alt=""/></Link></div>
            <div className="info">
                <p>{ 'We would like to kindly ask you complete you user profile. You will do this only once and you can modify your profile at any point.' }</p>
            </div>
            <Container>
                <FirstLoginForm/>
            </Container>
        </div>
        </>);
}