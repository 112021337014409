export const emptyUsageEvent = {
    _id: '',
    user: '',
    action: '',
    reference: '',
    start: 0,
    end: 0
}

export interface UsageEvent {
    _id: string;
    user: string;
    action: string;
    reference: string;
    start: number;
    end: number;
}
