export const emptyNlpEvent = {
    _id: '',
    user: '',
    group: '',
    subgroup: '',
    option: '',
    start: 0
}

export interface NlpEvent {
    _id: string;
    user: string;
    group: string;
    subgroup: string;
    option: string;
    start: number;
}
