import { useState } from "react";
import { PosTask } from "../interfaces/Task";
import Moment from 'moment';
import ConfirmationDialog from "../elements/ConfirmationDialog";
import EditPosTaskRepetition from "./PosTaskTypes/EditPosTaskRepetition";
import EditPosTaskExercises from "./PosTaskTypes/EditPosTaskExercises";
import EditPosTaskExplanationText from "./PosTaskTypes/EditPosTaskExplanationText";
import EditPosTaskReport from "./PosTaskTypes/EditPosTaskReport";
import EditPosTaskTeacherFeedback from "./PosTaskTypes/EditPosTaskTeacherFeedback";
import EditPosTaskTME from "./PosTaskTypes/EditPosTaskTME";
import EditPosTaskInteractionActivity from "./PosTaskTypes/EditPosTaskInteractionActivity";
import EditPosTaskVideo from "./PosTaskTypes/EditPosTaskVideo";
import EditPosTaskText from "./PosTaskTypes/EditPosTaskText";
import EditPosTaskPhoto from "./PosTaskTypes/EditPosTaskPhoto";
import EditPosTaskGame from "./PosTaskTypes/EditPosTaskGame";
import EditPosTaskDialog from "./PosTaskTypes/EditPosTaskDialog";
import EditPosTaskAudio from "./PosTaskTypes/EditPosTaskAudio";

export interface PosTaskTeaserProps {
    task_id: string;
    postask: PosTask;
    position: number;
    deleteCallback: CallableFunction;
    updateCallback: CallableFunction;
    cloneCallback: CallableFunction;
    sortUpCallback: CallableFunction;
    sortDownCallback: CallableFunction;
    cloneAsCallback: CallableFunction;
}

export default function PosTaskTeaser(props: PosTaskTeaserProps) {
    const [showConfirm, setShowConfirm] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const handleDelete = () => {
        props.deleteCallback(props.postask._id)
    }

    const askForConfirmation = () => {
        setShowConfirm(true);
    }

    const cancelDelete = () => {
        setShowConfirm(false);
    }
    
    const handleShowEdit = () => {
        setShowEdit(true);
    }

    const handleHideEdit = () => {
        setShowEdit(false);
    }

    const handleSave = (postask: PosTask) => {
        props.updateCallback(postask, props.position);
        setShowEdit(false);
    }

    const handleSortUp = () => {
        props.sortUpCallback(props.position);
    }

    const handleSortDown = () => {
        props.sortDownCallback(props.position);
    }

    const handleClone = () => {
        props.cloneCallback(props.position);
    }

    const handleCloneAs = (id: string, direction: string) => {
        props.cloneAsCallback(id, direction);
    } 

    return(
        <div className={"post-task-teaser-container " + props.postask.type } key={ 'post-task-' + props.postask._id }>
            <h4>{ props.postask.name }</h4>
            <div className="type">{ 'Type' }: { props.postask.type }</div>
            <div className="updated">{ Moment(props.postask.updated).format('DD/MM/YYYY') }</div>
            <div className="actions">
                <a className="sort-up" onClick={ handleSortUp } title={ 'Move Up' }></a>
                <a className="sort-down" onClick={ handleSortDown } title={ 'Move Up' }></a>
                <a className="clone-link" onClick={ handleClone } title={ 'Clone pre-task' }></a>
                <a className="edit-link" onClick={ handleShowEdit } title={ 'Edit pre-task' }></a>
                <a className="delete-link" onClick={ askForConfirmation } title={ 'Delete pre-task' }></a>
            </div>
            { showConfirm &&
                <ConfirmationDialog
                    message={ 'Are you sure you want to delete the post-task <strong>' + props.postask.name + '</strong>? This action cannot be undone.'}
                    confirmLabel={ 'Delete' } cancelLabel={ 'Cancel' }
                    confirmCallback={ handleDelete } cancelCallback={ cancelDelete } />
            }
            { props.postask.type == 'tme' && showEdit &&
                <EditPosTaskTME task_id={ props.task_id } id={ props.postask._id }
                    position={ props.position } saveForm={ handleSave } closeForm={ handleHideEdit } />
            }
            { props.postask.type == 'repetition' && showEdit &&
                <EditPosTaskRepetition task_id={ props.task_id } id={ props.postask._id }
                    position={ props.position } saveForm={ handleSave } closeForm={ handleHideEdit } />
            }
            { props.postask.type == 'interactionactivity' && showEdit &&
                <EditPosTaskInteractionActivity task_id={ props.task_id } id={ props.postask._id }
                    position={ props.position } saveForm={ handleSave } closeForm={ handleHideEdit } />
            }
            { props.postask.type == 'explanationtext' && showEdit &&
                <EditPosTaskExplanationText task_id={ props.task_id } id={ props.postask._id }
                    position={ props.position } saveForm={ handleSave } closeForm={ handleHideEdit } />
            }
            { props.postask.type == 'report' && showEdit &&
                <EditPosTaskReport task_id={ props.task_id } id={ props.postask._id }
                    position={ props.position } saveForm={ handleSave } closeForm={ handleHideEdit } />
            }
            { props.postask.type == 'teacherfeedback' && showEdit &&
                <EditPosTaskTeacherFeedback task_id={ props.task_id } id={ props.postask._id }
                    position={ props.position } saveForm={ handleSave } closeForm={ handleHideEdit } />
            }
            { props.postask.type == 'exercises' && showEdit &&
                <EditPosTaskExercises task_id={ props.task_id } id={ props.postask._id }
                    position={ props.position } saveForm={ handleSave } closeForm={ handleHideEdit } />
            }
            { props.postask.type == 'audio' && showEdit &&
                <EditPosTaskAudio task_id={ props.task_id } id={ props.postask._id } position={ props.position }
                    saveForm={ handleSave } closeForm={ handleHideEdit } cloneAs={ handleCloneAs } />
            }
            { props.postask.type == 'dialog' && showEdit &&
                <EditPosTaskDialog task_id={ props.task_id } id={ props.postask._id } position={ props.position }
                    saveForm={ handleSave } closeForm={ handleHideEdit } cloneAs={ handleCloneAs } />
            }
            { props.postask.type == 'game' && showEdit &&
                <EditPosTaskGame task_id={ props.task_id } id={ props.postask._id } position={ props.position }
                    saveForm={ handleSave } closeForm={ handleHideEdit } cloneAs={ handleCloneAs } />
            }
            { props.postask.type == 'photo' && showEdit &&
                <EditPosTaskPhoto task_id={ props.task_id } id={ props.postask._id } position={ props.position }
                    saveForm={ handleSave } closeForm={ handleHideEdit } cloneAs={ handleCloneAs } />
            }
            { props.postask.type == 'text' && showEdit &&
                <EditPosTaskText task_id={ props.task_id } id={ props.postask._id } position={ props.position }
                    saveForm={ handleSave } closeForm={ handleHideEdit } cloneAs={ handleCloneAs } />
            }
            { props.postask.type == 'video' && showEdit &&
                <EditPosTaskVideo task_id={ props.task_id } id={ props.postask._id } position={ props.position } 
                    saveForm={ handleSave } closeForm={ handleHideEdit } cloneAs={ handleCloneAs } />
            }

        </div>
    );
}