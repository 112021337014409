import { Button, Form } from "react-bootstrap";
import parse from 'html-react-parser';
import { Org } from "../../interfaces/Org";
import { useNavigate } from "react-router-dom";

export interface OrgDeleteProps {
    org: Org;
    submitCallback: CallableFunction;
    closeCallback: CallableFunction;
}

export default function OrgDelete(props: OrgDeleteProps) {
    const navigate = useNavigate();
    const handleClose = () => {
        props.closeCallback();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const requestOptionsDelete = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/org/' + props.org._id, requestOptionsDelete)
            .then(response => {
                props.submitCallback(props.org._id);
            })
            .catch(error => {
                navigate('/');
            });
    }

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ handleClose }></a>
                    <div className="admin-delete-faq-container">
                        <h4>{ 'Delete FAQ' }</h4>
                        <Form onSubmit={ handleSubmit }>
                            <Form.Text id="deleteConfirmationMessage">
                                { parse('You are about to delete organization content <strong>' + props.org.name + '</strong>. ' +
                                    'This action cannot be undone. ' +
                                    'Are you sure you want to proceed?')
                                }
                            </Form.Text>
                            <div className="actions">
                                <Button variant="primary" type="submit">{ 'Yes' }</Button>
                                <Button variant="secondary" onClick={ handleClose }>{ 'No' }</Button>
                            </div>
                        </Form>
                    </div>
                </div>  
            </div>
        </div>
    );
}