import { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { User } from "../../interfaces/User";
import { Org } from "../../interfaces/Org";
import { useNavigate } from "react-router-dom";

export interface UserEditProps {
    user: User;
    submitCallback: CallableFunction;
    closeCallback: CallableFunction;
}

export default function UserEdit(props: UserEditProps) {
    const navigate = useNavigate();
    const [hasPassword, setHasPassword] = useState(false);
    const [userPassword, setUserPassword] = useState('');
    const [orgs, setOrgs] = useState([]);
    const [org, setOrg] = useState((props.user.org === undefined || props.user.org == null) ? '' : props.user.org._id);

    const handleSetOrg = (e: any) => {
        setOrg(e.target.value);
    }

    const handleClose = () => {
        props.closeCallback();
    }

    const generatePassword = () => {
        var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var passwordLength = 12;
        var password = '';
        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber +1);
        }
        setHasPassword(true);
        setUserPassword(password);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let error = false;
        let error_messages: string[] = [];
        if (!event.target.password.value) {
            error_messages.push('You must generate or write a password for the user. Use the button below.');
            error = true;
        }
        const roles: string[] = [];
        if (event.target.student.checked) {
            roles.push('student');
        }
        if (event.target.teacher.checked) {
            roles.push('teacher');
        }
        if (event.target.admin.checked) {
            roles.push('admin');
        }
        if (roles.length == 0) {
            error = true;
            error_messages.push('You must give the user a role.');
        }
        if (error) {
            alert(error_messages.join('\n'));
        } else {
            const newUser = Object.assign({}, props.user);
            newUser.email = event.target.email.value;
            newUser.username = event.target.email.value;
            newUser.password = event.target.password.value;
            newUser.role = roles;
            newUser.profile.firstname = event.target.firstname.value;
            newUser.profile.lastname = event.target.lastname.value;
            if (org == '') {
                newUser.org = {
                    _id: '',
                    name: ''
                }
            } else {
                newUser.org = {
                    _id: org,
                    name: ''
                }
            }
            const requestOptionsPost = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newUser)
            };
            fetch('https://srv.taskgen.eu/v1/user/' + props.user._id, requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    const requestOptionsPost = {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    };
                    fetch('https://srv.taskgen.eu/v1/user/' + props.user._id, requestOptionsPost)
                        .then(response => {
                            if (response.status !== 200) {
                                navigate('/');
                            }
                            return response.json();
                        })
                        .then(data => {
                            props.submitCallback(data);                    
                        });                
                });
        }
    }

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/org', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setOrgs(data);
            });
    }, []);

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ handleClose }></a>
                    <div className="admin-add-user-container">
                        <Form onSubmit={ handleSubmit }>
                            <Form.Control type="hidden" id="id" value={ props.user._id } />
                            <FormGroup className="email" controlId="email">
                                <Form.Label aria-required={ true }>{ 'Email address' }</Form.Label>
                                <Form.Control type="email" required={ true } defaultValue={ props.user.email }
                                    placeholder={ 'Enter the email address of the user' } />
                            </FormGroup>
                            <FormGroup className="password" controlId="password">
                                <Form.Label aria-required={ true }>{ 'Password' }</Form.Label>
                                <Form.Control type="text" required={ true }
                                    defaultValue={ userPassword }></Form.Control>
                            </FormGroup>
                            <FormGroup className="role" controlId="role">
                                <Form.Label aria-required={ true }>{ 'Role' }</Form.Label>
                                <Form.Check type="switch" id="student" label={ 'Student' } defaultChecked={ props.user.role.findIndex(r => r == 'student') !== -1 } />
                                <Form.Check type="switch" id="teacher" label={ 'Teacher' } defaultChecked={ props.user.role.findIndex(r => r == 'teacher') !== -1 } />
                                <Form.Check type="switch" id="admin" label={ 'Administrator' } defaultChecked={ props.user.role.findIndex(r => r == 'admin') !== -1 } />
                            </FormGroup>
                            <Row>
                                <Col className="col-md-6">
                                    <FormGroup className="firstname" controlId="firstname">
                                        <Form.Label aria-required={ true }>{ 'Name' }</Form.Label>
                                        <Form.Control type="text" required={ true } defaultValue={ props.user.profile.firstname }
                                            placeholder={ 'Write the name of the user' } />
                                    </FormGroup>
                                </Col>
                                <Col className="col-md-6">
                                    <FormGroup className="lastname" controlId="lastname">
                                        <Form.Label aria-required={ true }>{ 'Lastname' }</Form.Label>
                                        <Form.Control type="text" required={ true } defaultValue={ props.user.profile.lastname }
                                            placeholder={ 'Write the lastname of the user' } />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup className="org" controlId="org">
                                <Form.Label aria-required={ true }>{ 'Organization' }</Form.Label>
                                <Form.Select aria-label={ '- Select an organization -' } onChange={ handleSetOrg } value={ org }>
                                    <option value=''>{ '- Select an organization -' }</option>
                                    { orgs.map((o: Org) => {
                                        return <option value={ o._id }>{ o.name }</option>
                                    })}
                                </Form.Select>
                            </FormGroup>
                            <div className="actions">
                                <Button variant="secondary" onClick={ generatePassword }>{ 'Generate password' }</Button>
                                <Button type="submit" variant="primary">{ 'Save' }</Button>
                            </div>
                        </Form>
                    </div>
                </div>  
            </div>
        </div>
    );
}