import { useEffect, useState } from "react";
import { Form, FormControl, FormLabel } from "react-bootstrap";
import { SubTask, SubTaskGroup, SubTaskPrompt, SubTaskRole, SubTaskStudent } from "../../interfaces/Task";
import SubTaskGroupItem from "./SubTaskGroupItem";
import SubTaskRoleItem from "./SubTaskRoleItem";
import SubTaskPromptItem from "./SubTaskPromptItem";
import SubTaskPromptEdit from "./SubTaskPromptEdit";
import Help from "../../elements/HelpContent";
import SubTaskRoleItemAssignment from "./SubTaskRoleItemAssignment";
import { Media } from "../../interfaces/Media";
import SubTaskTeacherNotesEdit from "./SubTaskTeacherNotesEdit";
import { registerEventEnd, registerEventStart } from "../../../utils/utils";

const emptySubTask = {
    _id: '',
    name: '',
    updated: new Date(),
    groups: [],
    roles: [],
    prompts: [],
    goal: '',
    students: 0,
    grouping: '',
    studentsPerGroup: 0,
    authorship: '',
    session: '',
    students_session: false,
    teachers_session: false,
    teacher_notes: ''
}

export interface EditSubTaskProps {
    taskId: string;
    //subtaskId: string;
    subTask: SubTask;
    saveCallback: CallableFunction;
    closeCallback: CallableFunction;
}

const emptySubTaskPrompt = {
    _id: '',
    name: '',
    instructions: '',
    media: [],
    text: '',
    roles: [],
    teacher_notes: ''
}

const emptySubTaskRole = {
    _id: '',
    name: '',
    color: '',
    groups: []
}

export default function EditSubTask(props: EditSubTaskProps) {
    const [eventId, setEventId] = useState('');
    const [showEditPrompt, setShowEditPrompt] = useState(false);
    const [showEditTeacherNotes, setShowEditTeacherNotes] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState<SubTaskPrompt>(emptySubTaskPrompt);
    const [selectedPromptPosition, setSelectedPromptPosition] = useState(0);
    const [showEditRoleAssignment, setShowEditRoleAssignment] = useState(false);
    const [selectedRole, setSelectedRole] = useState<SubTaskRole>(emptySubTaskRole);
    const [selectedRolePosition, setSelectedRolePosition] = useState(0);
    const [showTaskProperties, setShowTaskProperties] = useState(false);

    const handleShowEditPrompt = (p: SubTaskPrompt, position: number) => {
        setSelectedPrompt(p);
        setSelectedPromptPosition(position);
        setShowEditPrompt(true)
    }

    const handleShowEditRoleAssignment = (r: SubTaskRole, position: number) => {
        setSelectedRole(r);
        setSelectedRolePosition(position);
        setShowEditRoleAssignment(true)
    }

    const toggleShowTaskProperties = () => {
        setShowTaskProperties(!showTaskProperties);
        
    }

    const handleChangeProperty = (e: any) => {
        const newSubTask = Object.assign({}, props.subTask);
        switch (e.target.name) {
            case 'goal':
                newSubTask.goal = e.target.value;
                break;
            case 'students':
                newSubTask.students = e.target.value;
                break;
            case 'grouping':
                newSubTask.grouping = e.target.value;
                break;
            case 'groupingsize':
                newSubTask.studentsPerGroup = e.target.value;
                break;
        }
        // setSubTask(newSubTask);
        props.saveCallback(newSubTask);
    }

    const handleSavePrompt = (prompt: SubTaskPrompt, position: number) => {
        const newSubTask = Object.assign({}, props.subTask);
        newSubTask.prompts = props.subTask.prompts.map((p: SubTaskPrompt, index) => {
            if (index == position) {
                const newPrompt = Object.assign({}, p);
                newPrompt.instructions = prompt.instructions;
                newPrompt.media = prompt.media.map((m: Media) => {
                    return m;
                });
                newPrompt.name = prompt.name;
                newPrompt.text = prompt.text;
                newPrompt.teacher_notes = prompt.teacher_notes;
                newPrompt.roles = prompt.roles;
                return (newPrompt);
            } else {
                return p;
            }
        });
        // setSubTask(newSubTask);
        setShowEditPrompt(false);
        props.saveCallback(newSubTask);
    }

    const handleSaveRoleAssignment = (role: SubTaskRole, position: number) => {
        const newSubTask = Object.assign({}, props.subTask);
        newSubTask.roles = props.subTask.roles.map((r: SubTaskRole, index) => {
            if (index == position) {
                const newRole = Object.assign({}, r);
                newRole.groups = role.groups;
                return (newRole);
            } else {
                return r;
            }
        });
        // setSubTask(newSubTask);
        setShowEditRoleAssignment(false);
        props.saveCallback(newSubTask);
    }

    const handleDeletePrompt = (prompt: SubTaskPrompt, position: number) => {
        const newSubTask = Object.assign({}, props.subTask);
        newSubTask.groups = props.subTask.groups.map((g: SubTaskGroup) => {
            const newGroup = Object.assign({}, g);
            newGroup.students = g.students.map((s: SubTaskStudent) => {
                const newStudent = Object.assign({}, s);
                if (newStudent.prompt == position) {
                    newStudent.prompt = -1;
                }
                return newStudent;
            })
            return newGroup;
        });
        newSubTask.prompts = props.subTask.prompts.filter((p: SubTaskPrompt, index) => index != position);
        //setSubTask(newSubTask);
        props.saveCallback(newSubTask);
    }

    const handleHideEditPrompt = () => {
        setShowEditPrompt(false)
    }

    const handleHideEditRoleAssignment = () => {
        setShowEditRoleAssignment(false)
    }
    
    const handleAddNewGroup = () => {
        let newSubTask = Object.assign({}, props.subTask);
        let newGroup:SubTaskGroup = {
            _id: '',
            name: 'Group ' + (props.subTask.groups.length + 1),
            color: '',
            students: []
        }
        newSubTask.groups.push(newGroup);
        //setSubTask(newSubTask);
        props.saveCallback(newSubTask);
    }

    const handleDeleteGroup = (group: SubTaskGroup) => {
        const newSubTask = Object.assign({}, props.subTask);
        newSubTask.groups = props.subTask.groups.filter((g: SubTaskGroup) => g._id != group._id);
        //setSubTask(newSubTask);
        props.saveCallback(newSubTask);
    }

    const handleCloneGroup = (group: SubTaskGroup) => {
        const newSubTask = Object.assign({}, props.subTask);
        newSubTask.groups = props.subTask.groups.map((g: SubTaskGroup) => { return g; });
        newSubTask.groups.push(group)
        props.saveCallback(newSubTask);
    }

    const handleAddNewRole = () => {
        let newSubTask = Object.assign({}, props.subTask);
        let newRole:SubTaskRole = {
            _id: '',
            name: 'R' + (props.subTask.roles.length + 1),
            color: '',
            groups: []
        }
        newSubTask.roles.push(newRole);
        //setSubTask(newSubTask);
        props.saveCallback(newSubTask);
    }

    const handleSaveRole = (role: SubTaskRole, name: string, color: string, position: number) => {
        const newSubTask = Object.assign({}, props.subTask);
        newSubTask.roles = props.subTask.roles.map((r: SubTaskRole, index) => {
            if (index == position) {
                const updatedRole = Object.assign({}, r);
                updatedRole.name = name;
                updatedRole.color = color;
                return updatedRole;
            } else {
                return r;
            }
        });
        //setSubTask(newSubTask);
        props.saveCallback(newSubTask);
    }

    const handleDeleteRole = (role: SubTaskRole) => {
        const newSubTask = Object.assign({}, props.subTask);
        newSubTask.roles = props.subTask.roles.filter((r: SubTaskRole) => r._id != role._id);
        //setSubTask(newSubTask);
        props.saveCallback(newSubTask);
    }

    const handleAddNewPrompt = () => {
        let newSubTask = Object.assign({}, props.subTask);
        let newPrompt:SubTaskPrompt = {
            _id: '',
            name: 'Prompt ' + (props.subTask.prompts.length + 1),
            instructions: '',
            media: [],
            text: '',
            roles: [],
            teacher_notes: ''
        }
        newSubTask.prompts.push(newPrompt);
        //setSubTask(newSubTask);
        props.saveCallback(newSubTask);
    }

    const handleClonePrompt = (p: SubTaskPrompt) => {
        let newSubTask = Object.assign({}, props.subTask);
        let newPrompt:SubTaskPrompt = {
            _id: '',
            name: p.name + ' (copy)',
            instructions: p.instructions,
            media: p.media.map((m: Media) => { return m }),
            text: p.text,
            roles: p.roles,
            teacher_notes: p.teacher_notes
        }
        newSubTask.prompts.push(newPrompt);
        //setSubTask(newSubTask);
        props.saveCallback(newSubTask);
    }

    const handleClose = () => {
        registerEventEnd(eventId);
        props.closeCallback();
    }

    const handleSaveSubTask = (e: any) => {
        e.preventDefault();
        props.closeCallback();
    }

    const saveGroup = (group: SubTaskGroup, name: string, color: string, students: SubTaskStudent[], position: number) => {
        const newSubTask = Object.assign({}, props.subTask);
        newSubTask.groups = props.subTask.groups.map((g: SubTaskGroup, index) => {
            if (index == position) {
                const updatedGroup = Object.assign({}, g);
                updatedGroup.name = name;
                updatedGroup.color = color;
                updatedGroup.students = students;
                return updatedGroup;
            } else {
                return g;
            }
        });
        // setSubTask(newSubTask);
        props.saveCallback(newSubTask);
    }

    const handleNameChange = (e: any) => {
        const newSubTask = Object.assign({}, props.subTask);
        newSubTask.name = e.target.value;
        //setSubTask(newSubTask);
        props.saveCallback(newSubTask);
    }

    useEffect(() => {
        registerEventStart('edit subtask', props.taskId).then(data => { 
            setEventId(data);
        });
    
        return () => {
            registerEventEnd(eventId);
        };
    }, []);

    const nullSubmit = (e: any) => {
        e.preventDefault();
    }

    const handleShowEditTeacherNotes = (event: any) => {
        event.preventDefault()
        setShowEditTeacherNotes(true)
    }

    const handleSaveTeacherNotes = (teacher_notes: string) => {
        const newSubTask = Object.assign({}, props.subTask);
        newSubTask.teacher_notes = teacher_notes;
        // setSubTask(newSubTask);
        setShowEditTeacherNotes(false);
        props.saveCallback(newSubTask);
    }

    const handleHideEditTeacherNotes = () => {
        setShowEditTeacherNotes(false)
    }

    return(
        <div className="overlay">
            <div className="inner">
                { !showEditPrompt && !showEditTeacherNotes &&
                    <div className="overlay-content">
                        <a className="close" onClick={ handleClose }></a>
                        <div className="edit-sub-task-container">
                            <div className="inner">
                                <h2>{ 'Edit Main Task' }</h2>
                                <Form className="edit-sub-task-form" onSubmit={ handleSaveSubTask }>
                                    <Form.Group className="field field-title">
                                        <Form.Label>{ 'Main Task title' }</Form.Label>
                                        <Form.Control type="text" name="name" required
                                            value={ props.subTask.name } onChange={ handleNameChange } />
                                        {/*
                                        <div className={ 'task-properties' + (showTaskProperties ? ' show' : '')}>
                                            <div className="inner">
                                                <a className="toggle-properties" onClick={ toggleShowTaskProperties }>{ 'Configure your task' }</a>
                                                <div className="task-properties-form" onSubmit={ nullSubmit }>
                                                    <div className="form-field goal">
                                                        <FormLabel htmlFor="goal">{ 'The specific goal of this task phase is to…' }</FormLabel>
                                                        <FormControl as="textarea" value={ props.subTask.goal } name="goal" onChange={ handleChangeProperty } rows={ 10 } />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <a onClick={ handleShowEditTeacherNotes } className="teacher-notes">
                                            { 'Teacher notes' }
                                        </a>
                                    </Form.Group>
                                    <div className="sub-task-components">
                                        <div className="left">
                                            <div className="groups">
                                                { props.subTask.groups.map((g: SubTaskGroup, index) => {
                                                    return <SubTaskGroupItem group={ g } key={ 'group-' + g._id } position={ index }
                                                        saveCallback={ saveGroup } deleteCallback={ handleDeleteGroup } cloneCallback={ handleCloneGroup }
                                                        roles={ props.subTask.roles } prompts={ props.subTask.prompts } />
                                                })}
                                                <div className="add-group">
                                                    <a title={ 'Add new group' } onClick={ handleAddNewGroup }>
                                                        { 'Add new group' }
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="roles">
                                                <h4>{ 'Roles' }<Help id={ '650b0d67b0f9abea0305888f' } /></h4>
                                                <a className="add-new" title={ 'Add new role' } onClick={ handleAddNewRole }>
                                                    { 'Add new role' }
                                                </a>
                                                <div className="roles-list">
                                                    { props.subTask.roles.map((r: SubTaskRole, index) => {
                                                        return (<SubTaskRoleItem role={ r }
                                                            editCallback={ handleShowEditRoleAssignment }
                                                            saveCallback={ handleSaveRole }
                                                            deleteCallback={ handleDeleteRole }
                                                            assignmentsCallback={ handleShowEditRoleAssignment }
                                                            position={ index }
                                                            key={ 'role-' + r._id } />)
                                                    })}
                                                </div>
                                            </div>
                                            <div className="prompts">
                                                <h4>{ 'Prompts' }<Help id={ '650b1253b0f9abea030588b6' } /></h4>
                                                <a className="add-new" title={ 'Add new prompt' } onClick={ handleAddNewPrompt }>
                                                    { 'Add new prompt' }
                                                </a>
                                                <div className="prompts-list">
                                                    { props.subTask.prompts.map((p: SubTaskPrompt, index) => {
                                                            return (<SubTaskPromptItem prompt={ p } key={ 'group-' + p._id }
                                                                editPrompt={ handleShowEditPrompt }
                                                                deletePrompt={ handleDeletePrompt }
                                                                clonePrompt={ handleClonePrompt }
                                                                position={ index } />)
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="actions">
                                        <button className="btn btn-primary" type="submit" onClick={ handleSaveSubTask }>
                                            { 'Save' }
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                }
                { showEditPrompt &&
                    <div className="overlay-content">
                        <a className="close" onClick={ handleHideEditPrompt }></a>
                        { showEditPrompt &&
                            <SubTaskPromptEdit subTask={ props.subTask } prompt={ selectedPrompt } position={ selectedPromptPosition } save={ handleSavePrompt } />
                        }    
                    </div>
                }
                { showEditTeacherNotes &&
                    <div className="overlay-content">
                        <a className="close" onClick={ handleHideEditTeacherNotes }></a>
                        { showEditTeacherNotes &&
                            <SubTaskTeacherNotesEdit subTask={ props.subTask } save={ handleSaveTeacherNotes } />
                        }    
                    </div>
                }
                { showEditRoleAssignment &&
                    <div className="overlay-content">
                        <a className="close" onClick={ handleHideEditRoleAssignment }></a>
                        { showEditRoleAssignment &&
                            <SubTaskRoleItemAssignment subTask={ props.subTask } role={ selectedRole }
                                position={ selectedRolePosition } saveCallback={ handleSaveRoleAssignment } />
                        }    
                    </div>
                }
            </div>
        </div>
    );
}