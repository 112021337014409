import { Form } from "react-bootstrap";
import { SubTask, SubTaskPrompt, TaskSession } from "../../interfaces/Task";
import SubTaskPromptPreview from "./SubTaskPromptPreview";
import Moment from 'moment';
import { useNavigate } from "react-router-dom";

export interface PreviewSubTaskProps {
    task_id: string;
    subtask: SubTask;
    sessions: TaskSession[]
}

export default function PreviewSubTask(props: PreviewSubTaskProps) {
    const navigate = useNavigate();
    const handleCalendarChange = (event: any) => {
        const body = { session: event.target.value };
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body)
        };
        const reqUrl = 'https://srv.taskgen.eu/v1' +
            '/task/' + props.task_id +
            '/subtask/' + props.subtask._id +
            '/session';
        fetch(reqUrl, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {});
    }

    const handleStudentsChange = (event: any) => {
        const body = { students_session: event.target.checked };
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body)
        };
        const reqUrl = 'https://srv.taskgen.eu/v1' +
            '/task/' + props.task_id +
            '/subtask/' + props.subtask._id +
            '/students';
        fetch(reqUrl, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {});
    }

    const handleTeachersChange = (event: any) => {
        const body = { teachers_session: event.target.checked };
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body)
        };
        const reqUrl = 'https://srv.taskgen.eu/v1' +
            '/task/' + props.task_id +
            '/subtask/' + props.subtask._id +
            '/teachers';
        fetch(reqUrl, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {});
    }

    return(
        <div className="preview-subtask-item">
            <div className="inner">
                <h4>{ props.subtask.name }</h4>
                { props.subtask.goal &&
                    <div className="goal">
                        { props.subtask.goal }
                    </div>
                }
                <div className="subtask-options">
                    <Form.Group controlId="calendar" className="calendar">
                        <Form.Select name="session" onChange={ handleCalendarChange }>
                            <option value="">{ '- Select a session -' }</option>
                            { props.sessions.map((s: TaskSession) => {
                                return <option selected={ props.subtask.session == s._id } value={ s._id }>{ s.name + ' (' + Moment(s.datetime).format('DD/MM/YYYY') + ')' }</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="field-student-share">
                        <Form.Check type="checkbox" id={ 'share-with-students-' + props.subtask._id } 
                            key={ 'share-with-students-' + props.subtask._id } label={ 'Students' }
                            onChange={ handleStudentsChange } defaultChecked={ props.subtask.students_session }
                        />
                    </Form.Group>
                    <Form.Group className="field-teachers-share">
                        <Form.Check type="checkbox" id={ 'share-with-teachers-' + props.subtask._id } 
                            key={ 'share-with-teachers-' + props.subtask._id } label={ 'Teachers' }
                            onChange={ handleTeachersChange } defaultChecked={ props.subtask.teachers_session }
                        />
                    </Form.Group>
                </div>
                <div className="prompts">
                    <h5>{ 'Prompts' }</h5>
                    { props.subtask.prompts.length == 0 &&
                        <div className="message error">
                            { 'There are no prompts defined'}
                        </div>
                    }
                    { props.subtask.prompts.length > 0 &&
                        props.subtask.prompts.map((p: SubTaskPrompt) => {
                            return <SubTaskPromptPreview task_id={ props.task_id }
                                subtask_id={ props.subtask._id }
                                prompt={ p } />
                        })
                    }
                </div>
            </div>
        </div>
    );
}