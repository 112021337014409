import { Button } from "react-bootstrap";
import parse from 'html-react-parser';

interface ConfirmationDialogProps {
    message:  string;
    confirmLabel: string;
    confirmCallback: CallableFunction;
    cancelLabel: string;
    cancelCallback: CallableFunction;
}


export default function ConfirmationDialog(props: ConfirmationDialogProps) {
    const handleCancel = () => {
        props.cancelCallback();
    }

    const handleConfirm = () => {
        props.confirmCallback();
    }

    return (
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <div className="confirmation-dialog-container confirmation-dialog">
                        <div className="inner">
                            <h2>{ 'Delete task' }</h2>
                            <p>
                                { parse(props.message) }
                            </p>
                            <div className="actions">
                                <Button className="btn btn-primary" onClick={ handleConfirm }>{ props.confirmLabel }</Button>
                                <Button className="btn btn-warning" onClick={ handleCancel }>{ props.cancelLabel }</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}