import { useState } from "react";
import { SubTask, SubTaskGroup, SubTaskRole } from "../../interfaces/Task";
import { Form } from "react-bootstrap";

export interface SubTaskRoleItemAssignmentProps {
    subTask: SubTask;
    role: SubTaskRole;
    saveCallback: CallableFunction;
    position: number;
}

export default function SubTaskRoleItemAssignment(props: SubTaskRoleItemAssignmentProps) {
    const [groups, setGroups] = useState<number[]>(props.role.groups);

    const handleSave= () => {
        const newRole = Object.assign({}, props.role);
        newRole.groups = groups;
        props.saveCallback(newRole, props.position);
    }

    const handleGroupChange = (e: any) => {
        const newGroups = groups.map(r => { return r });
        const index = groups.indexOf(parseInt(e.target.value));
        if (e.target.checked) {
            if (index == -1) {
                // Not exists
                newGroups.push(parseInt(e.target.value));
                setGroups(newGroups);
            }
        } else {
            if (index != -1) {
                setGroups(newGroups.filter((r: number) => r != parseInt(e.target.value) ));
            }
        }
    }

    return(
        <div className="edit-sub-task-role-container">
            <div className="inner">
                <h2>{ 'Rol assignment to groups' }</h2>
                <Form className="sub-task-role-form" onSubmit={ handleSave }>
                    <strong>{ 'Assignments for the role: ' + props.role.name }</strong>
                    <p>{ 'Select the groups this role is assigned to' }</p>
                    <div className="group-assign">
                        { props.subTask.groups.map((r: SubTaskGroup, index) => {
                            const groupIndex = props.role.groups.indexOf(index);
                            const checked = (groupIndex != -1)
                            return (<div className="group-checkbox" key={ 'groups-assign-' + index }>
                                    <input type="checkbox" name="groupAssign"
                                        defaultChecked={ checked }
                                        value={ index } onChange={ handleGroupChange } /> { r.name }
                                </div>)
                        })}
                    </div>
                    <div className="actions">
                        <button className="btn btn-primary" type="submit">
                            { 'Save' }
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    );
}