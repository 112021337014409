export const emptyFaq = {
    _id: '',
    title: '',
    body: '',
    weight: 0
}

export interface Faq {
    _id: string,
    title: string,
    body: string,
    weight: number
}
