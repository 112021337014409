import { useEffect, useState } from "react";
import { User } from "../interfaces/User";
import { useNavigate } from "react-router-dom";
import EditProfile from "./EditProfile";

export default function UserTeaser() {
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<User>();
    const [showEditProfile, setShowEditProfile] = useState(false);
    
    const handleLogout = () => {
        localStorage.setItem('token', '');
        localStorage.setItem('userId', '');
        localStorage.setItem('userRole', '');
        localStorage.setItem('userEmail', '');
        localStorage.setItem('userLevel', '');
        localStorage.setItem('isAdmin', '');
        navigate("/");
    }

    const handleOpenEditProfile = () => {
        setShowEditProfile(true);
    }

    const handleCloseEditProfile = () => {
        setShowEditProfile(false);
    }

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/user/' + userId, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setCurrentUser(data);
            });
    }, []);

    return(<div className="user-teaser">
                <div className="image-container">
                    { currentUser && currentUser.profile.avatar && 
                        <img src={ currentUser.profile.avatar } alt={ currentUser.profile.firstname + ' ' + currentUser.profile.lastname } />
                    }
                    { !currentUser || !currentUser.profile.avatar &&
                        <div className="avatar"></div>
                    }
                </div>
                <div className="text-container">
                    <div className="name">
                        { currentUser && (currentUser.profile.firstname || currentUser.profile.lastname) &&
                            currentUser.profile.firstname + ' ' + currentUser.profile.lastname
                        }
                        { currentUser && !(currentUser.profile.firstname || currentUser.profile.lastname) &&
                            currentUser.email
                        }
                    </div>
                    <div className="actions">
                        <a onClick={ handleOpenEditProfile } title="Update profile">{ 'Profile' }</a>
                        <a onClick={ handleLogout } title="Logout your taskGen session">{ 'Logout' }</a>
                    </div>
                </div>
                { showEditProfile && <EditProfile closeCallback={ handleCloseEditProfile } />}
            </div>
        );
}