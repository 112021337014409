import { useState } from "react";

interface CloneAsProps {
    id: string;
    direction: string;
    cloneAs: CallableFunction;
}

export default function CloneAs(props: CloneAsProps) {
    const handleCopyCloneAs = () => {
        props.cloneAs(props.id, props.direction);
    }

    return (
        <a className="btn btn-warning clone-as" onClick={ handleCopyCloneAs }>
            { props.direction == 'pre-to-pos' && 'Clone as Pos-task' }
            { props.direction == 'pos-to-pre' && 'Clone as Pre-task' }
        </a>
    );
}