export interface User {
    _id: string;
    email: string;
    username: string;
    password: string;
    role: string[];
    privacy_policy: boolean,
    profile: {
        firstname: string;
        lastname: string;
        teaching_location: string[],
        teaching_model: string[],
        teaching_framework: string[],
        country: string,
        region: string,
        teaching_years: number,
        avatar: string,
        level: string,
        level_ask_again: boolean,
        tblt: boolean,
        tblt_hours: number
    };
    org: {
        _id: string,
        name: string
    }
}

export const emptyUser: User = {
    _id: '',
    email: '',
    username: '',
    password: '',
    role: [],
    privacy_policy: false,
    profile: {
        firstname: '',
        lastname: '',
        teaching_location: [],
        teaching_model: [],
        teaching_framework: [],
        country: '',
        region: '',
        teaching_years: 0,
        avatar: '',
        level: '',
        level_ask_again: true,
        tblt: false,
        tblt_hours: 0
    },
    org: {
        _id: '',
        name: ''
    }
}
