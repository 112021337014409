import logo from '../../logo.svg';
import { useState } from 'react';
import LoginEmailForm from './LoginEmailForm';
import RegisterForm from './RegisterForm';

export default function Login() {
    const [showRegister, setShowRegister] = useState(false);

    const showRegisterForm = () => {
        setShowRegister(true);
    }

    const handleRegistration = () => {
        setShowRegister(false);
    }

    return (<><div className="red-background"></div>
        <div className="login-container">
            <div className="logo">
                <img alt="Task-Gen" src= {logo}/>
            </div>
            { !showRegister &&
                <div className="login-email">
                    <LoginEmailForm />
                    <div className="register-action-container">
                        { 'You don\'t have an account?' }
                        <a onClick={ showRegisterForm } title={ 'Register as a new user'}>
                            { 'Register'}
                        </a>
                    </div>
                </div>
            }
            { showRegister && <RegisterForm registerCallback={ handleRegistration } />} 
        </div></>);
}
