import Moment from 'moment';
import { Task } from "../interfaces/Task";
import { useNavigate } from "react-router-dom";

interface TaskItemProps {
    task: Task;
    duplicateCallback: CallableFunction;
}

export default function TaskItemExample(props: TaskItemProps) {
    const navigate = useNavigate();
    const handleDuplicateTask = () => {
        const newTask = Object.assign({}, props.task);
        newTask._id = '';
        newTask.name = props.task.name + ' (copy)';
        newTask.userId = localStorage.getItem('userId') ?? '';
        newTask.clonned = true;
        newTask.original_source = 'example';
        newTask.original_task = props.task._id;
        
        // Create a new task
        const requestOptionsPost = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newTask)
        };
        fetch('https://srv.taskgen.eu/v1/task', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                props.duplicateCallback(data);
            });
    }

    return(
        <div className="task-item teaser" key={ 'task-' + props.task._id }>
            <div className="title">
                { props.task.name }
            </div>
            <div className="meta">
                <div className="changed">
                    { 'Last updated' }: <span>{ Moment(props.task.updated).format('DD/MM/YYYY') }</span>
                </div>
                <div className="actions">
                    <button className="no-decoration duplicate" title="Duplicate" onClick={ handleDuplicateTask }></button>
                </div>
            </div>
        </div>
    );
}