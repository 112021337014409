import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { PosTask } from "../../interfaces/Task";
import { Media } from "../../interfaces/Media";
import Uploader from "../../elements/Uploader";
import CustomEditor from "../../elements/CustomEditor";
import AnalyzedText from "../../elements/AnalyzedText";
import Help from "../../elements/HelpContent";
import { useNavigate } from "react-router-dom";
import AITools from "../../elements/AITools";
import CloneAs from "../../elements/CloneAs";

const emptyPosTask: any = {
    _id: '',
    name: '',
    type: 'photo',
    instructions: '',
    media: [],
    text: '',
    updated: new Date()
}

export interface EditPosTaskPhotoProps {
    task_id: string;
    id: string;
    position: number;
    saveForm: CallableFunction;
    closeForm: CallableFunction;
    cloneAs: CallableFunction;
}

export default function EditPosTaskPhoto(props: EditPosTaskPhotoProps) {
    const navigate = useNavigate();
    const [postTask, setPosTask] = useState<PosTask>(emptyPosTask);
    const [instructions, setInstructions] = useState('');
    const [teacher_notes, setTeacherNotes] = useState('');
    const [text, setText] = useState('');
    const [media, setMedia] = useState<Media[]>([]);
    const [analyzedText, setAnalyzedText] = useState();
    const [showAnalyze, setShowAnalyze] = useState(false);

    const handleMediaUpload = (media: Media[]) => {
        const newMedia = media.map((m: Media) => {
            return m;
        });
        setMedia(newMedia);
    }
    
    const handleInstructionsChange = (data: string) => {
        setInstructions(data);
    }

    const handleTeacherNotesChange = (data: string) => {
        setTeacherNotes(data);
    }

    const handleTextChange = (data: string) => {
        setText(data);
    }

    const handleClose = () => {
        props.closeForm();
    }

    const handleSave = (e: any) => {
        e.preventDefault();
        postTask.name = e.target.name.value;
        postTask.instructions = instructions;
        postTask.media = media.map((m: Media) => { return m; });
        postTask.text = text;
        postTask.teacher_notes = teacher_notes;
        props.saveForm(postTask);
    }

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/task/' + props.task_id + '/postask/' + props.id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                const newMedia: Media[] = data.media.map((m: Media) => { return m; });
                setMedia(newMedia);
                setPosTask(data);
            });
    }, []);

    const handleTextAnalyzed = (data: any) => {
        if (data.length > 0) {
            setAnalyzedText(data);
            setShowAnalyze(true);
        }
    }

    const handleCloneAs = (id: string, direction: string) => {
        props.cloneAs(id, direction);
    }

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ handleClose }></a>
                    <div className="new-task-container photo">
                        <div className="inner">
                            <h2>
                                { 'Edit Post-Task: Photo' }
                                <Help id={ '652fd38b1e5cf99991b571a6' } />
                            </h2>
                            <AITools />
                            <Form className="new-post-task-form" onSubmit={ handleSave }>
                                <div className="fields-container">
                                    <Form.Group className="field field-title">
                                        <Form.Label>{ 'Title of the Post-Task' }</Form.Label>
                                        <Form.Control type="text" name="name" required defaultValue={ postTask.name } />
                                    </Form.Group>
                                    <div className="field field-instructions">
                                        <CustomEditor
                                            change={ handleInstructionsChange }
                                            data={ postTask.instructions }
                                            id="instructions"
                                            label={ 'Aim and instructions'}
                                            npl={ false }
                                        />
                                    </div>
                                    <div className="field field-teachernotes">
                                        <CustomEditor
                                            change={ handleTeacherNotesChange }
                                            data={ postTask.teacher_notes }
                                            id="teachernotes"
                                            label={ 'Teacher notes '}
                                            npl={ false }
                                        />
                                    </div>
                                    <Uploader media={ media } save={ handleMediaUpload } />
                                    <div className="field field-text">
                                        <CustomEditor
                                            change={ handleTextChange }
                                            data={ postTask.text }
                                            id="text"
                                            label={ 'Text '}
                                            npl={ true }
                                        />
                                    </div>
                                </div>
                                { showAnalyze && <AnalyzedText analyzedText={ analyzedText } /> }
                                <div className="actions">
                                    <CloneAs id={ props.id } direction={ 'pos-to-pre' } cloneAs={ handleCloneAs } />
                                    <button className="btn btn-primary" type="submit">
                                        { 'Save' }
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}