import { useState } from "react";
import { Media } from "../interfaces/Media";
import Dropzone, { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";

export interface UploaderProps {
    media: Media[];
    save: CallableFunction;
}

export default function Uploader(props: UploaderProps) {
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
    const [files, setFiles] = useState<File[]>([]);

    const {getRootProps, getInputProps} = useDropzone({
        multiple: true,
        accept: {
            'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'],
            'audio/*': ['.mp3'], 'video/mp4': ['.mp4'],
            'application/pdf': ['.pdf'],
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/zip': ['.zip'], 'application/x-rar': ['.rar'], 'text/plain': ['.txt']
        }
    });

    const uploadFiles = (f: any[]) =>  {
        setFiles(f);
        if (f.length > 0) {
            const formData = new FormData();
            formData.append('type', 'asset');
            formData.append('user_id', userId ? userId : '');
            for(let i =0; i < f.length; i++) {
                formData.append("files", f[i]);
            }
            const requestOptionsPost = {
                method: 'POST',
                body: formData,
                headers: {
                    // 'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };
            fetch('https://srv.taskgen.eu/v1/upload', requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    let newMedia: Media[] = [];
                    if (data.length > 0) {
                        newMedia = [
                            ...props.media,
                            ...data.map((f: any) => {
                                return ({
                                    name: f.filename,
                                    path: f.path,
                                    mimetype: f.mimetype,
                                    share: false,
                                    prints: 0
                                })
                            })
                        ];
                    }
                    props.save(newMedia);
                })
                .catch((err) => console.log(err));
        }
    }

    const deleteMedia = (event: any) => {
        event.preventDefault();
        const newMedia = props.media.filter((m: Media, index) => index != event.target.dataset.position);
        props.save(newMedia);
    }

    return (
        <div className="field field-media">
            <div className="label">{ 'Media' }</div>
            { props.media.length > 0 &&
                <div className="media-items-list">
                    { props.media.map((m: Media, index) => {
                        switch (m.mimetype) {
                            case 'image/jpeg':
                            case 'image/png':
                                return (<div className="image media-item">
                                    <img src={ 'https://srv.taskgen.eu/' + m.path } />
                                    <button className="delete" onClick={ deleteMedia } data-position={ index } />
                                </div>);
                                break;
                            case 'video/mp4':
                                return (<div className="video media-item">
                                    <video controls>
                                        <source src={ 'https://srv.taskgen.eu/' + m.path } />
                                    </video>
                                    <button className="delete" onClick={ deleteMedia } data-position={ index } />
                                </div>);
                                break;
                            case 'audio/mpeg':
                                return (<div className="audio media-item">
                                    <audio controls>
                                        <source src={ 'https://srv.taskgen.eu/' + m.path } type="audio/mpeg" />
                                    </audio>
                                    <button className="delete" onClick={ deleteMedia } data-position={ index } />
                                </div>);
                                break;
                            case 'application/pdf':
                                return (<div className="pdf media-item">
                                    <a href={ 'https://srv.taskgen.eu/' + m.path } target="_blank" title={ m.name }></a>
                                    <button className="delete" onClick={ deleteMedia } data-position={ index } />
                                </div>);
                                break;
                            case 'application/vnd.ms-powerpoint':
                            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                                return (<div className="presentation media-item">
                                    <a href={ 'https://srv.taskgen.eu/' + m.path } target="_blank" title={ m.name }></a>
                                    <button className="delete" onClick={ deleteMedia } data-position={ index } />
                                </div>);
                                break;
                            case 'application/vnd.ms-excel':
                            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                                return (<div className="spreadsheet media-item">
                                    <a href={ 'https://srv.taskgen.eu/' + m.path } target="_blank" title={ m.name }></a>
                                    <button className="delete" onClick={ deleteMedia } data-position={ index } />
                                </div>);
                                break;
                            case 'application/msword':
                            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                return (<div className="document media-item">
                                    <a href={ 'https://srv.taskgen.eu/' + m.path } target="_blank" title={ m.name }></a>
                                    <button className="delete" onClick={ deleteMedia } data-position={ index } />
                                </div>);
                                break;
                            case 'application/zip':
                            case 'application/x-rar':
                                return (<div className="compress media-item">
                                    <a href={ 'https://srv.taskgen.eu/' + m.path } target="_blank" title={ m.name }></a>
                                    <button className="delete" onClick={ deleteMedia } data-position={ index } />
                                </div>);
                                break;
                            case 'text/plain':
                                return (<div className="text media-item">
                                    <a href={ 'https://srv.taskgen.eu/' + m.path } target="_blank" title={ m.name }></a>
                                    <button className="delete" onClick={ deleteMedia } data-position={ index } />
                                </div>);
                                break;
                        }})
                    }
                </div>
            }
            { props.media.length > 0 &&
                <div className="uploaded-files">
                    { props.media.map((f: any) => {
                        return (
                            <div className="image" style={ { backgroundImage: 'url(' + 'https://srv.taskgen.eu/' + f + ')' } }></div>
                        )
                    }) }
                </div>
            }
            {<Dropzone onDrop={acceptedFiles => uploadFiles(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <section>
                    <div {...getRootProps()}>
                        <input name="files" {...getInputProps()} />
                        <p>Drag and Drop files here</p>
                    </div>
                    </section>
                )}
                </Dropzone> }
        </div>
    );
}