import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { User } from "../interfaces/User";
import { useNavigate } from "react-router-dom";

interface UserLevelProps {
    nextCallback: CallableFunction;
}

export default function UserLevel(props: UserLevelProps) {
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');

    const [level, setLevel] = useState('');
    const [askAgain, setAskAgain] = useState(false);

    const handleLevelChange = (e: any) => {
        setLevel(e.target.value);
    }

    const handleAskAgainChange = (e: any) => {
        setAskAgain(e.target.checked);
    }

    const handleNext = () => {
        if (level == '') {
            alert('It is important to tell us the level of expertise you have using taskGen.');
        } else {
            const requestOptionsGet = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };
            fetch('https://srv.taskgen.eu/v1/user/' + userId, requestOptionsGet)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    let user: User = data;
                    user.profile.level = level;
                    user.profile.level_ask_again = askAgain;

                    const requestOptionsPost = {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        },
                        body: JSON.stringify(user.profile)
                    };
                    fetch('https://srv.taskgen.eu/v1/user/profile/' + userId, requestOptionsPost)
                        .then(response => {
                            if (response.status !== 200) {
                                navigate('/');
                            }
                            return response.json();
                        })
                        .then(data => {
                            props.nextCallback();
                        });
                });
        }
    }

    useEffect(() => {
        const requestOptionsGet = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/user/' + userId, requestOptionsGet)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                let user: User = data;
                if (user.profile.level_ask_again == true) {
                    props.nextCallback();
                }
            });
    }, []);

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <div className="user-level-container">
                        <div className="step step-1">
                            <h2>{ 'User Level' }</h2>
                            <div className="message">
                                {'How much assistance do you need in the task design process? Please select the option you feel more comfortable with. You can change this setting later in your profile page.'}
                            </div>
                            <div className="level">
                                <div className="option">
                                    <div className="option-title">
                                        { 'Rookie' }
                                    </div>
                                    <div className="selector">
                                        <div className="label">
                                            { 'I\'m a rookie and need assistance, some training, and examples of tasks.' }
                                            
                                        </div>
                                        <div className="radio">
                                            <input name="level" type="radio" value="rookie" onChange={ handleLevelChange } />
                                        </div>
                                    </div>
                                </div>
                                <div className="option">
                                    <div className="option-title">
                                        { 'Advanced' }
                                    </div>
                                    <div className="selector">
                                        <div className="label">
                                            { 'I have some experience, but I still would like some help and training.' }
                                        </div>
                                        <div className="radio">
                                            <input name="level" type="radio" value="advanced" onChange={ handleLevelChange } />
                                        </div>
                                    </div>
                                </div>
                                <div className="option">
                                    <div className="option-title">
                                        { 'Expert' }
                                    </div>
                                    <div className="selector">
                                        <div className="label">
                                            { 'Let me just do it!' }
                                        </div>
                                        <div className="radio">
                                            <input name="level" type="radio" value="expert" onChange={ handleLevelChange } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ask-again">
                                <input type="checkbox" name="ask-again" onChange={ handleAskAgainChange } />
                                <span>{ 'Don\'t ask me again' }</span>
                            </div>
                            <div className="actions">
                                <Button className="btn btn-primary" onClick={ handleNext }>
                                    { 'Next'} &raquo;
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}