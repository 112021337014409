
import parse from "html-react-parser";
import { SubTaskPrompt } from "../../interfaces/Task";
import { Media } from "../../interfaces/Media";
import MediaPreview from "../../elements/MediaPreview";
import { Table } from "react-bootstrap";
import { replaceOembed } from "../../../utils/utils";

export interface SiteSubTaskPromptProps {
    prompt: SubTaskPrompt;
    mode: string;
}

export default function SiteSubTaskPrompt(props: SiteSubTaskPromptProps) {
    return(
        <div className="site-sub-task-prompt">
            <div className="inner">
                <div className="prompt-name">
                    <strong>{ props.prompt.name }</strong>
                </div>
                <div className="prompt-content">
                    <div className={ 'instructions' + (props.prompt.media.filter((m: Media) => m.share == true).length == 0 ? ' no-medias' : '') }>
                        { props.prompt.instructions &&
                            <div className="inner">
                                <label><strong>{ 'Aim and instructions' }</strong></label>
                                <div className="value">{ parse(replaceOembed(props.prompt.instructions)) }</div>
                            </div>
                        }
                    </div>
                    { props.prompt.media.length > 0 &&
                        <div className="medias-list">
                            <Table responsive={ true }>
                                <thead>
                                    <tr>
                                        <th>{ 'Resource' }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { props.prompt.media.map((m: Media) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <MediaPreview media={ m } />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    }
                    { props.prompt.teacher_notes && props.mode == 't' &&
                        <div className={ 'teacher-notes' }>
                            <label><strong>{ 'Teacher notes' }</strong></label>
                            <div className="value">{ parse(replaceOembed(props.prompt.teacher_notes)) }</div>
                        </div>
                    }
                    <div className="text">
                        { !props.prompt.text &&
                            <div className="message">
                                { 'This prompt has no text' }
                            </div>
                        }
                        { props.prompt.text &&
                            <div className="inner">
                                <label><strong>{ 'Text' }</strong></label>
                                <div className="value">{ parse(replaceOembed(props.prompt.text)) }</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}