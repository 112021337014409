import { Button, Form, FormGroup } from "react-bootstrap";
import { useState } from "react";
import {emptyFeedback} from "../interfaces/Feedback";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

export interface FeedbackProps {
}

const sectionOptions = [
    {value: 'login', label: 'Login'},
    {value: 'dashboard', label: 'Dashboard'},
    {value: 'tasks', label: 'Tasks'},
    {value: 'pretasks', label: 'Pretasks'},
    {value: 'postasks', label: 'Postasks'},
    {value: 'task-groups', label: 'Task groups'},
    {value: 'task-roles', label: 'Task roles'},
    {value: 'task-prompts', label: 'Task prompts'},
    {value: 'text-analyzer', label: 'Text analyzer'},
    {value: 'my-design', label: 'My design'},
    {value: 'students-site', label: 'Shared with students'},
    {value: 'teachers-site', label: 'Shared with teachers'},
    {value: 'help', label: 'Help system'}
];

export default function FeedbackBlock() {
    const navigate = useNavigate();
    const [section, setSection] = useState('');
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);

    const handleClose = () => {
        setShowFeedbackForm(false);
    }

    const handleSetSection = (e: any) => {
        setSection(e.value);
    }

    const handleShowFeedbackForm = () => {
        setShowFeedbackForm(true);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let error = false;
        let error_messages: string[] = [];

        if (error) {
            alert(error_messages.join('\n'));
        } else {
            const newFeedback = Object.assign({}, emptyFeedback);
            newFeedback.user._id = localStorage.getItem("userId")+'';
            newFeedback.section = section;
            newFeedback.issue = event.target.subject.value;
            newFeedback.body = event.target.body.value;
            newFeedback.state = 0;
            const requestOptionsPost = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newFeedback)
            };
            fetch('https://srv.taskgen.eu/v1/feedback', requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    handleClose();
                    alert('Thanks for your feedback.');
                });
        }
    }

    return(
        <div className="feedback-container">
            <a onClick={handleShowFeedbackForm}>Feedback</a>
            {showFeedbackForm &&
            <div className="overlay">
                <div className="inner">
                    <div className="overlay-content">
                        <a className="close" onClick={ handleClose }></a>
                        <div className="admin-add-feedback-container">
                            <h4>{ 'Add new Feedback' }</h4>
                            <div className="feedback-form-container">
                                <Form onSubmit={ handleSubmit }>
                                    <FormGroup className="form-field field-section" controlId = "section">
                                        <Form.Label aria-required={ true }>{ 'Section' }</Form.Label>
                                        <Select options={sectionOptions} onChange={ handleSetSection } required />
                                        <div className="description">
                                            { 'Select the functionality of the application about which you want to comment' }
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="form-field field-subject" controlId="subject">
                                        <Form.Label aria-required={ true }>{ 'Subject' }</Form.Label>
                                        <Form.Control type="text" required={ true } name="subject"
                                                      placeholder={ 'Write the subject of your comment' } />
                                    </FormGroup>
                                    <FormGroup className="form-field field-body" controlId="body">
                                        <Form.Label aria-required={ true }>{ 'Comment' }</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder={ 'Leave your comments here' }
                                            required={ true }
                                            rows={ 5 } />
                                    </FormGroup>
                                    <div className="actions">
                                        <Button type="submit" variant="primary">{ 'Save' }</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}