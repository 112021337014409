import { useState } from "react";
import { PreTask } from "../interfaces/Task";
import Moment from 'moment';
import ConfirmationDialog from "../elements/ConfirmationDialog";
import EditPreTaskAudio from "./PreTaskTypes/EditPreTaskAudio";
import EditPreTaskDialog from "./PreTaskTypes/EditPreTaskDialog";
import EditPreTaskPhoto from "./PreTaskTypes/EditPreTaskPhoto";
import EditPreTaskSpeech from "./PreTaskTypes/EditPreTaskSpeech";
import EditPreTaskText from "./PreTaskTypes/EditPreTaskText";
import EditPreTaskVideo from "./PreTaskTypes/EditPreTaskVideo";
import EditPreTaskActivity from "./PreTaskTypes/EditPreTaskActivity";
import EditPreTaskGame from "./PreTaskTypes/EditPreTaskGame";
import EditPreTaskWarmUp from "./PreTaskTypes/EditPreTaskWarmUp";

export interface PreTaskTeaserProps {
    task_id: string;
    pretask: PreTask;
    position: number;
    deleteCallback: CallableFunction;
    updateCallback: CallableFunction;
    cloneCallback: CallableFunction;
    sortUpCallback: CallableFunction;
    sortDownCallback: CallableFunction;
    cloneAsCallback: CallableFunction;
}

export default function PreTaskTeaser(props: PreTaskTeaserProps) {
    const [showConfirm, setShowConfirm] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const handleDelete = () => {
        setShowConfirm(false);
        props.deleteCallback(props.pretask._id)
    }

    const askForConfirmation = () => {
        setShowConfirm(true);
    }

    const cancelDelete = () => {
        setShowConfirm(false);
    }

    const handleShowEdit = () => {
        setShowEdit(true);
    }

    const handleHideEdit = () => {
        setShowEdit(false);
    }

    const handleCloneAs = (id: string, direction: string) => {
        props.cloneAsCallback(id, direction);
    } 

    const handleSave = (pretask: PreTask) => {
        props.updateCallback(pretask, props.position);
        setShowEdit(false);
    }

    const handleSortUp = () => {
        props.sortUpCallback(props.position);
    }

    const handleSortDown = () => {
        props.sortDownCallback(props.position);
    }

    const handleClone = () => {
        props.cloneCallback(props.position);
    }

    const decodePretaskType = (type: string) => {
        switch (type) {
            case 'dialog':
                return 'written dialogue';
                break;
            default:
                return type;
        }
    }

    return(
        <div className={'pre-task-teaser-container ' + props.pretask.type } key={ 'pre-task-' + props.pretask._id }>
            <h4>{ props.pretask.name }</h4>
            <div className="type">{ 'Type' }: { decodePretaskType(props.pretask.type) }</div>
            <div className="updated">{ Moment(props.pretask.updated).format('DD/MM/YYYY') }</div>
            <div className="actions">
                <a className="sort-up" onClick={ handleSortUp } title={ 'Move Up' }></a>
                <a className="sort-down" onClick={ handleSortDown } title={ 'Move Up' }></a>
                <a className="clone-link" onClick={ handleClone } title={ 'Clone pre-task' }></a>
                <a className="edit-link" onClick={ handleShowEdit } title={ 'Edit pre-task' }></a>
                <a className="delete-link" onClick={ askForConfirmation } title={ 'Delete pre-task' }></a>
            </div>
            { showConfirm &&
                <ConfirmationDialog
                    message={ 'Are you sure you want to delete the pre-task <strong>' + props.pretask.name + '</strong>? This action cannot be undone.'}
                    confirmLabel={ 'Delete' } cancelLabel={ 'Cancel' }
                    confirmCallback={ handleDelete } cancelCallback={ cancelDelete } />
            }
            { props.pretask.type == 'audio' && showEdit &&
                <EditPreTaskAudio task_id={ props.task_id } id={ props.pretask._id } position={ props.position }
                    saveForm={ handleSave } closeForm={ handleHideEdit }  cloneAs={ handleCloneAs } />
            }
            { props.pretask.type == 'dialog' && showEdit &&
                <EditPreTaskDialog task_id={ props.task_id } id={ props.pretask._id } position={ props.position }
                    saveForm={ handleSave } closeForm={ handleHideEdit } cloneAs={ handleCloneAs } />
            }
            { props.pretask.type == 'photo' && showEdit &&
                <EditPreTaskPhoto task_id={ props.task_id } id={ props.pretask._id } position={ props.position }
                    saveForm={ handleSave } closeForm={ handleHideEdit } cloneAs={ handleCloneAs } />
            }
            { props.pretask.type == 'speech' && showEdit &&
                <EditPreTaskSpeech task_id={ props.task_id } id={ props.pretask._id }
                    position={ props.position } saveForm={ handleSave } closeForm={ handleHideEdit } />
            }
            { props.pretask.type == 'text' && showEdit &&
                <EditPreTaskText task_id={ props.task_id } id={ props.pretask._id } position={ props.position }
                    saveForm={ handleSave } closeForm={ handleHideEdit } cloneAs={ handleCloneAs } />
            }
            { props.pretask.type == 'video' && showEdit &&
                <EditPreTaskVideo task_id={ props.task_id } id={ props.pretask._id } position={ props.position }
                    saveForm={ handleSave } closeForm={ handleHideEdit } cloneAs={ handleCloneAs } />
            }
            { props.pretask.type == 'activity' && showEdit &&
                <EditPreTaskActivity task_id={ props.task_id } id={ props.pretask._id }
                    position={ props.position } saveForm={ handleSave } closeForm={ handleHideEdit } />
            }
            { props.pretask.type == 'game' && showEdit &&
                <EditPreTaskGame task_id={ props.task_id } id={ props.pretask._id } position={ props.position }
                    saveForm={ handleSave } closeForm={ handleHideEdit } cloneAs={ handleCloneAs } />
            }
            { props.pretask.type == 'warmup' && showEdit &&
                <EditPreTaskWarmUp task_id={ props.task_id } id={ props.pretask._id }
                    position={ props.position } saveForm={ handleSave } closeForm={ handleHideEdit } />
            }
        </div>
    );
}